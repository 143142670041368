<template>
  <v-container fluid>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab bottom right fixed color="primary"
          v-bind="attrs" v-on="on" @click="dialogForm = {action: 'Create User', showForm: true, user: {}}">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Create User</span>
    </v-tooltip>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :no-data-text="'No data found'"
          :no-results-text="'No results found'"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon @click="dialogForm = {action: 'Edit User', showForm: true, user: Object.assign(item)}">
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.update_password="{ item }">
          <v-btn icon @click="dialogForm = {action: 'Update Password', showForm: true, user: Object.assign(item)}">
            <v-icon>mdi-pencil-lock</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.is_active="{ item }">
          <span>{{ item.is_active ? 'Active' : 'Inactive'}}</span>
        </template>
        <template v-slot:item.role="{ item }">
          <span class="text-capitalize">{{ item.role }}</span>
        </template>
        <template v-slot:progress>
          <div class="full-width text-center ma-4">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <user-form 
      :user="dialogForm.user"
      :isAdmin="true"
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      @formSubmitted="resetDialog();getUsers();"
      @closeDialog="resetDialog()" />
  </v-container>
</template>

<script>
  import UserForm from './blocks/UserForm';

  export default {
    name: 's-user',
    components: {
      UserForm,
    },
    data() {
      return {
        dialogForm: {
          showForm: false,
          action: '',
          user: {},
        },
        search: '',
        loading: false,
        headers: [
          {text: 'Id', value: 'id', align: 'start'},
          {text: 'Name', value: 'name', align: 'center'},
          {text: 'Email', value: 'email', align: 'center'},
          {text: 'Mobile', value: 'mobile', align: 'center'},
          {text: 'Role', value: 'role', align: 'center'},
          {text: 'Is Active', value: 'is_active', align: 'center'},
          {text: 'Edit', value: 'edit', align: 'center'},
          {text: 'Update Password', value: 'update_password', align: 'center'},
        ],
        items: [],
      };
    },
    methods: {
      resetDialog() {
        this.dialogForm = {showForm: false, action: '', user: {}};
      },
      getUsers() {
        this.loading = true;
        this.$api.users.index()
          .then((response) => {
            this.items = response.users;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    created() {
      if (!this.$root.hasAccess('')) {
        this.$store.dispatch('setAlert', {
          status: 'error',
          show: true,
          message: "Please contact admin to access this page",
        });
        this.$router.push({name: 'home'});
      }
      this.getUsers();
    },
  };
</script>