import * as users from './api/users';
import * as cards from './api/cards';
import * as vessels from './api/vessels';
import * as service_catalogs from './api/service_catalogs';
import * as operations from './api/operations';
import * as operation_captain_details from './api/operation_captain_details';
import * as operation_billed_items from './api/operation_billed_items';
import * as service_catalog_pricings from './api/service_catalog_pricings';
import * as sessions from './api/sessions';
import * as vessel_voyages from './api/vessel_voyages';
import * as vessel_owners from './api/vessel_owners';
import * as supply_boat_pricings from './api/supply_boat_pricings';
import * as passenger_boat_pricings from './api/passenger_boat_pricings';

export default {
  users,
  vessels,
  sessions,
  cards,
  service_catalogs,
  operations,
  operation_captain_details,
  operation_billed_items,
  service_catalog_pricings,
  vessel_voyages,
  vessel_owners,
  supply_boat_pricings,
  passenger_boat_pricings
};