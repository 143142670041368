<template>
  <div>
    <v-select
      label="Source Location"
      placeholder="Select a Source Location"
      outlined
      :rules="[rules.required]"
      dense
      v-model="localMetadata.source_location"
      prepend-inner-icon="mdi-map-marker"
      :items="locations"
    ></v-select>
    <v-select
      label="Destination Location"
      placeholder="Select a Destination Location"
      outlined
      :rules="[rules.required]"
      dense
      v-model="localMetadata.destination_location"
      prepend-inner-icon="mdi-map-marker"
      :items="locations"
    ></v-select>
    <v-select
      label="Persons"
      placeholder="Select No. of Persons"
      outlined
      :rules="[rules.required]"
      dense
      v-model="selectedPersons"
      prepend-inner-icon="mdi-account-group"
      :items="personOptions"
      item-text="name"
      item-value="value"
    ></v-select>
    <v-text-field
      label="Midnight Charge"
      placeholder="Enter Midnight Charge"
      type="number"
      outlined
      dense
      v-model="localMetadata.midnight_charge"
      prepend-inner-icon="mdi-currency-usd"
      clearable
    ></v-text-field>
  </div>
</template>

<script>
  import { PERSON_OPTIONS } from '../../../../services/constants/constants';

  export default {
    props: {
      metadata: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        localMetadata: {
          source_location: '',
          destination_location: '',
          persons: '',
          midnight_charge: ''
        },
        locations: [
          'Brani Terminal',
          'CBD',
          'Changi Airport',
          'Harbourfront Center',
          'Hotel',
          'Jurong',
          'Keppel Terminal',
          'Keppel Tuas',
          'Marina South Pier',
          'Mega yard at Tuas',
          'Pasir Panjang Terminal',
          'PSA',
          'Sembawang',
          'Tuas Area Shipyard',
          'Weat Coast Pier',
          'Woodlands'
        ],
        selectedPersons: null,
        personOptions: PERSON_OPTIONS,
        rules: {
          required: (value) => !!value || 'Required.',
        },
      };
    },
     watch: {
      metadata: {
        handler(newMetadata) {
          this.localMetadata = { ...this.localMetadata, ...newMetadata };
          this.setInitialPersons();
        },
        immediate: true,
        deep: true,
      },
      selectedPersons(newValue) {
        this.localMetadata.persons = newValue;
      },
    },
    methods: {
      getMetadata() {
        return this.localMetadata;
      },
      setInitialPersons() {
        const personOption = this.personOptions.find(option => {
          return option.name === this.localMetadata.persons;
        });
        this.$nextTick(() => {
          this.selectedPersons = personOption ? personOption.value : null;
        });
      },
    },
    created() {
      this.setInitialPersons();
    },
  };
</script>