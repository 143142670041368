import http from '../axios';

export function index() {
  return http.get('/vessels');
}

export function create(payload){
  return http.post('/vessels', payload);
}

export function update(payload) {
  return http.patch(`/vessels/${payload.id}`, payload);
}

export function findVessel(vessel_name){
  return http.get(`/vessels/find_vessel?vessel_name=${vessel_name}`);
}

export function getVesselInfo(vessel_name){
  return http.get(`/vessels/get_vessel_info?vessel_name=${vessel_name}`);
}