<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="form" @submit.prevent="submit">
          <v-card-text class="pb-0">
            <v-select
              label="Sector"
              placeholder="Select a sector"
              outlined
              :rules="[rules.required]"
              dense
              v-model="supplyBoatPricingForm.sector"
              prepend-inner-icon="mdi-map-marker"
              :items = "sectors"
              @change="onSectorChange"
            ></v-select>
            <v-select
              v-if="supplyBoatPricingForm.sector === 'IPL'"
              label="Launch Location"
              placeholder="Select a Launch Location"
              outlined
              :rules="[rules.required]"
              dense
              v-model="supplyBoatPricingForm.launch_location"
              prepend-inner-icon="mdi-map-marker"
              :items = "launchLocation"
            ></v-select>
            <v-select
              v-if="supplyBoatPricingForm.sector === 'IPL'"
              label="Anchorage Location"
              placeholder="Select a Anchorage Location"
              outlined
              :rules="[rules.required]"
              dense
              v-model="supplyBoatPricingForm.anchorage_location"
              prepend-inner-icon="mdi-map-marker"
              :items = "anchorageLocation"
            ></v-select>
            <v-select
              v-if="supplyBoatPricingForm.sector === 'OPL'"
              label="SubSector"
              placeholder="Select a Subsector"
              outlined
              :rules="[rules.required]"
              dense
              v-model="supplyBoatPricingForm.sub_sector"
              prepend-inner-icon="mdi-map-marker-plus"
              :items = "subSectors"
            ></v-select>
            <v-select
              label="Pallets"
              placeholder="Select a Pallet"
              outlined
              :rules="[rules.required]"
              dense
              v-model="selectedPallets"
              prepend-inner-icon="mdi-shipping-pallet"
              :items="palletOptions"
              item-text="name"
              item-value="value"
            ></v-select>
            <v-select
              label="No. of Hours"
              placeholder="Select hours"
              outlined
              dense
              v-model="selectedHours"
              prepend-inner-icon="mdi-hours-24"
              :items="hourOptions"
              item-text="name"
              item-value="value"
            ></v-select>
            <v-text-field
              label="Price"
              placeholder="Enter Price"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              v-model="supplyBoatPricingForm.price"
              prepend-inner-icon="mdi-currency-usd"
              clearable
            ></v-text-field>
            <v-text-field
              label="Fuel Surcharge"
              placeholder="Enter Fuel Surcharge"
              type="number"
              outlined
              dense
              v-model="supplyBoatPricingForm.fuel_surcharge"
              prepend-inner-icon="mdi-currency-usd"
              clearable
            ></v-text-field>
            <v-select
              label="Supplier"
              placeholder="Select a supplier"
              :rules="[rules.required]"
              outlined
              dense
              v-model="supplyBoatPricingForm.card_id"
              prepend-inner-icon="mdi-account"
              :items="suppliers"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="primary" outlined @click="formSubmit()" 
              :disabled="loading" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '../../../utilities/validator';
  import { PALLET_OPTIONS, HOUR_OPTIONS } from '../../../services/constants/constants';

  export default {
    name: 's-supply-boat-form',
    props: {
      action: {
        type: String,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      suppliers: Array,
      supply_boat_pricing: {
        type: Object,
        default: {},
      },
    },
    data() {
      let supplyBoatPricingForm = {
        anchorage_location: '',
        launch_location: '',
        sector: '',
        sub_sector: '',
        pallets: [],
        hours: [],
        price: '',
        fuel_surcharge: '',
        card_id: ''
      };

      return {
        form: false,
        rules,
        loading: false,
        selectedPallets: null,
        selectedHours: null,
        launchLocation: ['Penjuru Terminal', 'Marina South Wharves'],
        anchorageLocation: ['Western Working', 'Western Petroleum A, B', 'Sudong Special Purpose', 'Raffles', 'Very Large Crude Carrier', 'Eastern Working', 'Eastern Petroleum A, B', 'Eastern Special Purpose A, B', 'Eastern Bunkering C', 'Eastern Bunkering B, A', 'Changi General Purpose'],
        sectors: ['IPL', 'OPL'],
        subSectors: [
          'EOPL - 104 18E',
          'EOPL - 104 21E',
          'HORSBURG - 104 18E',
          'HORSBURG - 104 21E',
          'South China Sea',
          'WOPL - 104 18E'
        ],
        palletOptions: [
          { name: 'Upto 10 pallets', value: 10 },
          { name: '11 to 20 pallets', value: 20 },
          { name: 'Above 20 pallets', value: 21 }
        ],
        hourOptions: [
          { name: 'First 4 hours', value: 4 },
          { name: 'Subsequent hrs', value: 5 }
        ],
        tmpForm: Object.assign({}, supplyBoatPricingForm),
        supplyBoatPricingForm: Object.assign({}, supplyBoatPricingForm),
      };
    },
    methods: {
      getPalletName(value) {
        const pallet = this.palletOptions.find(pallet => pallet.value === value);
        return pallet ? pallet.name : value;
      },
      getHourName(value) {
        const hour = this.hourOptions.find(hour => hour.value === value);
        return hour ? hour.name : value;
      },
      onSectorChange() {
        if (this.supplyBoatPricingForm.sector === 'IPL') {
          this.supplyBoatPricingForm.sub_sector = '';
        }
        if (this.supplyBoatPricingForm.sector === 'OPL') {
          this.supplyBoatPricingForm.launch_location = '';
          this.supplyBoatPricingForm.anchorage_location = '';
        }
      },
      submit() {
        return this.$refs.form.validate();
      },
      formSubmit() {
        if(!this.submit()) return;
        this.loading = true;
        if (this.supplyBoatPricingForm.id) {
          this.updateForm();
        } else {
          this.saveService();
        }
      },
      updateForm() {
        this.supplyBoatPricingForm.pallets = this.selectedPallets;
        this.supplyBoatPricingForm.hours = this.selectedHours;

        this.$api.supply_boat_pricings.update(this.supplyBoatPricingForm)
          .then((response) => {
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Supply Boat Pricing updated successfully',
            });
          })
          .catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      saveService() {
        // Set the selected key-value pairs to supplyBoatPricingForm object
        this.supplyBoatPricingForm.pallets = this.selectedPallets;
        this.supplyBoatPricingForm.hours = this.selectedHours;

        this.$api.supply_boat_pricings.create(this.supplyBoatPricingForm)
          .then((response) => {
            this.supplyBoatPricingForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Supply Boat Pricing saved successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
    },
    watch: {
      supply_boat_pricing(value) {
        // Mapping names to values
        const palletValue = this.palletOptions.find(option => option.name === value.pallets)?.value;
        const hourValue = this.hourOptions.find(option => option.name === value.hours)?.value;

        this.supplyBoatPricingForm = Object.assign({}, value);
        this.selectedPallets = palletValue || null;
        this.selectedHours = hourValue || null;

        if (!value.id) this.$refs.form && this.$refs.form.reset();
      },
    },
  };
</script>
