import http from '../axios';

export function index() {
  return http.get('/passenger_boat_pricings');
}

export function create(payload){
  return http.post('/passenger_boat_pricings', payload);
}

export function update(payload) {
  return http.patch(`/passenger_boat_pricings/${payload.id}`, payload);
}

export function get_pricing_details(){
   return http.get('/passenger_boat_pricings/get_passenger_pricing_details');
 }