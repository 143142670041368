export const PALLET_OPTIONS = [
  { name: 'Upto 10 pallets', value: 10 },
  { name: 'Upto 20 pallets', value: 20 },
  { name: 'Above 20 pallets', value: 21 },
];

export const HOUR_OPTIONS = [
  { name: 'First 4 hours', value: 4 },
  { name: 'Subsequent hrs', value: 5 }
];

export const PERSON_OPTIONS = [
  { name: '1 to 5 PAX', value: 5 },
  { name: '6 to 10 PAX', value: 6 }
];

export const FRESHWATER_OPTIONS = [
  { name: 'First 50,000', value: 1 },
  { name: '50,000 to 1,00,000', value: 2 },
  { name: '1,00,000 to 1,50,000', value: 3 },
  { name: '1,50,000 to 2,00,000', value: 4 },
  { name: '2,00,000 to 2,50,000', value: 5 },
  { name: '2,50,000 to 3,00,000', value: 6 },
  { name: '3,00,000 to 3,50,000', value: 7 },
  { name: '3,50,000 to 4,00,000', value: 8 },
  { name: 'Above 4,00,000', value: 9 }
];

export const CBM_OPTIONS = [
  { name: 'First 5 m3', value: 1 },
  { name: 'Above 5 m3', value: 2 }
];