<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Job Scope*"
            placeholder="Select a job scope"
            :rules="[rules.required]"
            outlined
            dense
            v-model="operationBaseFieldsForm.job_scope"
            prepend-inner-icon="mdi-script"
            :items="jobScopes"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Vessel*"
            placeholder="Select a vessel"
            :rules="[rules.required]"
            outlined
            dense
            v-model="operationBaseFieldsForm.vessel_id"
            prepend-inner-icon="mdi-ferry"
            :items="vessels"
            item-text="name"
            item-value="id"
            @change="setCustomer"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Customer*"
            placeholder="Select a customer"
            outlined
            :rules="[rules.required]"
            dense
            v-model="operationBaseFieldsForm.card_id"
            prepend-inner-icon="mdi-account"
            :items="owners"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-menu
            v-model="jobStartDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedJobStartDateFormatted"
                label="Job Start Date*"
                dense
                :rules="[rules.required]"
                outlined
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="operationBaseFieldsForm.job_start_date"
              no-title
              @input="jobStartDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-datetime-picker
            ref="eta"
            label="Estimated Time of Arrival*"
            v-model="operationBaseFieldsForm.eta"
            :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, rules: [rules.required], id: 'etaField'}"
          >
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-calendar-clock</v-icon>
            </template>                                                         
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-datetime-picker
            ref="etd"
            label="Estimated Time of Departure*"
            v-model="operationBaseFieldsForm.etd"
            :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, rules: [rules.required], id: 'etdField'}"
          >
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-calendar-clock</v-icon>
            </template>                                                         
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select v-if="$root.hasAccess()"
            label="Incharge*"
            placeholder="Select Incharge"
            :rules="[rules.required]"
            outlined
            dense
            v-model="operationBaseFieldsForm.supervisor_id"
            prepend-inner-icon="mdi-account"
            :items="users"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Arrival Remarks"
            :rows="2"
            placeholder="Enter Arrival Remarks"
            outlined
            dense
            v-model="operationBaseFieldsForm.remarks_arrival"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Departure Remarks"
            :rows="2"
            placeholder="Enter Departure Remarks"
            outlined
            dense
            v-model="operationBaseFieldsForm.remarks_departure"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="!isDraft">
        <v-col cols="12">
          <v-switch
            dense
            hide-details
            v-model="operationBaseFieldsForm.vessel_information"
            label="Include Vessel Arrival - Departure Information?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row v-if="operationBaseFieldsForm.vessel_information">
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Captain Email Address"
            placeholder="Enter Captain Email"
            outlined
            dense
            v-model="vesselInfoForm.captain_mail_id"
            prepend-inner-icon="mdi-gmail"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Vessel Contact"
            placeholder="Enter Vessel Contact"
            outlined
            dense
            v-model="vesselInfoForm.vessel_contact"
            prepend-inner-icon="mdi-phone"
            clearable
          ></v-text-field>
        </v-col>
        <v-col v-if="false" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-btn color="primary" outlined>
            Send Mail To Captain
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Coming From"
            placeholder="Select coming from"
            outlined
            dense
            v-model="vesselInfoForm.coming_from"
            prepend-inner-icon="mdi-arrow-bottom-right"
            :items="['East', 'West']"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Going To"
            placeholder="Select going to"
            outlined
            dense
            v-model="vesselInfoForm.going_to"
            prepend-inner-icon="mdi-arrow-top-right"
            :items="['East', 'West']"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Last Port"
            placeholder="Enter Last Port"
            outlined
            dense
            v-model="vesselInfoForm.last_port"
            prepend-inner-icon="mdi-arrow-bottom-right"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Next Port"
            placeholder="Enter Next Port"
            outlined
            dense
            v-model="vesselInfoForm.next_port"
            prepend-inner-icon="mdi-arrow-top-right"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Forward"
            placeholder="Enter FWD"
            type="number"
            outlined
            dense
            v-model="vesselInfoForm.fwd"
            prepend-inner-icon="mdi-format-align-top"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="AFT"
            placeholder="Enter AFT"
            type="number"
            outlined
            dense
            v-model="vesselInfoForm.aft"
            prepend-inner-icon="mdi-format-align-bottom"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Total Height of Ship"
            placeholder="Enter Height"
            type="number"
            outlined
            dense
            v-model="vesselInfoForm.ht"
            prepend-inner-icon="mdi-format-align-middle"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Freeboard"
            placeholder="Enter Freeboard"
            type="number"
            outlined
            dense
            v-model="vesselInfoForm.freeboard"
            prepend-inner-icon="mdi-arrow-expand-up"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Cargo Type"
            placeholder="Enter Cargo Type"
            outlined
            dense
            v-model="vesselInfoForm.cargo_type"
            prepend-inner-icon="mdi-select-all"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Quantity / Volume(Metric Ton)"
            placeholder="Enter Quantity / Volume"
            outlined
            type="number"
            dense
            v-model="vesselInfoForm.qty_volume"
            prepend-inner-icon="mdi-select-inverse"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="pb-0">
          <span class="headline">Arrival Details</span>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Inbound Voyage"
            placeholder="Enter Inbound Voyage"
            outlined
            type="text"
            dense
            v-model="vesselInfoForm.arrival.inbound_voyage"
            prepend-inner-icon="mdi-arrow-bottom-right"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Weather(Fair / Wet)"
            placeholder="Enter Weather Details"
            outlined
            dense
            :items="weatherItems"
            v-model="vesselInfoForm.arrival.weather"
            prepend-inner-icon="mdi-weather-cloudy"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <span class="headline">Departure Details</span>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Outbound Voyage"
            placeholder="Enter Outbound Voyage"
            outlined
            type="text"
            dense
            v-model="vesselInfoForm.departure.outbound_voyage"
            prepend-inner-icon="mdi-arrow-top-right"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Weather(Fair / Wet)"
            placeholder="Enter Weather Details"
            outlined
            dense
            :items="weatherItems"
            v-model="vesselInfoForm.departure.weather"
            prepend-inner-icon="mdi-weather-cloudy"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-switch
            dense
            hide-details
            v-model="vesselInfoForm.bunker_details"
            label="Include Bunker Details?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" class="pb-0">
          <span class="headline">Bunker Details</span>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" class="pb-0">
          <span class="body-1">Fuel Oil Details</span>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Grade"
            placeholder="Select a grade"
            outlined
            dense
            v-model="vesselInfoForm.bunker.fuel_oil_grade"
            prepend-inner-icon="mdi-omega"
            :items="grades"
          ></v-select>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Fuel Oil Quantity(tons)"
            placeholder="Enter Fuel Oil Quantity"
            outlined
            dense
            type="number"
            v-model="vesselInfoForm.bunker.fuel_oil_volume"
            prepend-inner-icon="mdi-oil"
            clearable
          ></v-text-field>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Fuel Oil Intake Rate / Hr"
            placeholder="Enter Intake Rate"
            outlined
            dense
            type="number"
            v-model="vesselInfoForm.bunker.oil_intake_rate"
            prepend-inner-icon="mdi-filter"
            clearable
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Fuel Oil CST"
            placeholder="Enter Fuel Oil CST"
            outlined
            dense
            type="number"
            v-model="vesselInfoForm.bunker.fuel_oil_cst"
            prepend-inner-icon="mdi-oil-temperature"
            clearable
          ></v-text-field>
        </v-col> -->
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" class="pb-0">
          <span class="body-1">Gas Oil Details</span>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Grade"
            placeholder="Select a grade"
            outlined
            dense
            v-model="vesselInfoForm.bunker.gas_oil_grade"
            prepend-inner-icon="mdi-omega"
            :items="grades"
          ></v-select>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Gas Oil Quantity(tons)"
            placeholder="Enter Gas Oil Quantity"
            outlined
            dense
            type="number"
            v-model="vesselInfoForm.bunker.gas_oil_volume"
            prepend-inner-icon="mdi-oil"
            clearable
          ></v-text-field>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Gas Oil Intake Rate"
            placeholder="Enter Gas Oil Intake Rate"
            outlined
            dense
            type="number"
            v-model="vesselInfoForm.bunker.gas_intake_rate"
            prepend-inner-icon="mdi-filter"
            clearable
          ></v-text-field>
        </v-col>
        <v-col v-if="vesselInfoForm.bunker_details" cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-switch
            dense
            hide-details
            v-model="vesselInfoForm.freshwater_details"
            label="Include Freshwater Details?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col v-if="vesselInfoForm.freshwater_details" cols="12" class="pb-0">
          <span class="headline">Freshwater Details</span>
        </v-col>
        <v-col v-if="vesselInfoForm.freshwater_details" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Freshwater Quantity"
            placeholder="Enter Freshwater Quantity"
            outlined
            dense
            type="number"
            v-model="vesselInfoForm.bunker.freshwater_quantity"
            prepend-inner-icon="mdi-water"
            clearable
          ></v-text-field>
        </v-col>
        <v-col v-if="vesselInfoForm.freshwater_details" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Freshwater Intake Rate"
            placeholder="Enter Freshwater Intake Rate"
            outlined
            dense
            type="number"
            v-model="vesselInfoForm.bunker.freshwater_intake_rate"
            prepend-inner-icon="mdi-cup-water"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <ReportDetails :operation="operation" />
      <v-btn style="background-color: #273978; color: white;" class="mt-7" outlined @click="formSubmit()"
        :disabled="loading" :loading="loading">
        Save & Continue
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../utilities/validator';
  import _ from 'lodash';
  import ReportDetails from '../ReportDetails';

  export default {
    name: 's-job-details',
    components: {
      ReportDetails
    },
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false
      }
    },
    data() {
      let operationBaseFieldsObj = {
        job_scope: 'Full Agent',
        job_start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        card_id: null,
        vessel_id: null,
        eta: null,
        etd: null,
        is_draft: false,
        supervisor_id: null,
        remarks_arrival: '',
        remarks_departure: '',
        vessel_information: false
      };

      let vesselInfoObj = {
        captain_mail_id: '',
        coming_from: '',
        going_to: '',
        last_port: '',
        next_port: '',
        fwd: '',
        aft: '',
        ht: '',
        freeboard: '',
        cargo_type: '',
        qty_volume: '',
        vessel_contact: '',
        bunker_details: false,
        freshwater_details: false,
        arrival: {
          weather: '',
          inbound_voyage: ''
        },
        departure: {
          weather: '',
          outbound_voyage: ''
        },
        bunker: {
          oil_intake_rate: '',
          gas_intake_rate: '',
          fuel_oil_grade: '',
          fuel_oil_volume: '',
          // fuel_oil_cst: '',
          gas_oil_grade: '',
          gas_oil_volume: '',
          lube_oil_grade: '',
          lube_oil_volume: '',
          freshwater_quantity: '',
          freshwater_intake_rate: '',
        },
      };

      return {
        jobScopes: ["Full Agent", "Owner’s Matter", "Charterer", "Cargo Operation", "Handover / takeover", "OPL(outside port limits)"],
        customers: [],
        bunkerSuppliers: [],
        lubeOilSuppliers: [],
        surveyors: [],
        vessels: [],
        owners: [],
        users: [],
        jobStartDateMenu: false,
        bunkerFieldsPopulted: false,
        eTDMenu: false,
        eTAMenu: false,
        form: false,
        rules,
        loading: false,
        tmpForm: Object.assign({}, operationBaseFieldsObj),
        operationBaseFieldsForm: Object.assign({}, operationBaseFieldsObj),
        grades: [
          'High',
          'Intermediate',
          'Medium',
          'Low',
          'Very Low Sulphur',
          'Not Indicated'
        ],
        weatherItems: ['Fair','Wet'],
        tmpFormVessel: Object.assign({}, vesselInfoObj),
        vesselInfoForm: Object.assign({}, vesselInfoObj),
      };
    },
    computed: {
      computedJobStartDateFormatted: {
        get() {
          return this.formatDate(this.operationBaseFieldsForm.job_start_date);  
        },
        set(value) {
          return value;
        }
      },
    },
    methods: {
      setCustomer(value) {
        this.$api.vessel_owners.index(value)
          .then((response) => {
            this.owners = response.owners;
            // this.operationBaseFieldsForm.card_id = this.owners[0];
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
        if(!this.operation.id) {
          const vessel = _.find(this.vessels, {id: value});
          this.vesselInfoForm.captain_mail_id = vessel.email;
          this.vesselInfoForm.vessel_contact = vessel.vessel_contact;
        }
      },
      submit() {
        return this.$refs.form.validate();
      },
      formSubmit() {
        if(!this.submit()) return;

        this.loading = true;
        this.operationBaseFieldsForm.is_draft = this.isDraft;
        const cardId = this.operationBaseFieldsForm.card_id;
        const formData = {
          operation_details: { ...this.operationBaseFieldsForm, card_id: cardId },
          vessel_information: this.operationBaseFieldsForm.vessel_information ? { ...this.vesselInfoForm } : null
        };
        this.$api.operations.create(formData)
          .then(response => {
            this.operation.id = response.operation.id;
            this.$emit('nextStep', 2, response.operation);
          }).catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
      formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-');
        return `${year}-${month}-${day}`;
      },
      populateDropdown() {
        this.getVessels();
        this.getUsers();
        this.populateCards('customers', '');
      },
      getUsers() {
        this.$api.users.index()
          .then((response) => {
            this.users = response.users;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getVessels() {
        this.$api.vessels.index()
          .then((response) => {
            this.vessels = response.vessels;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      populateCards(field, card_type) {
        this.$api.cards.index(card_type)
          .then((response) => {
            this[field] = response.cards;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      preserveForm(value) {
        const [etaDay, etaMonth, etaYear] = new Date(value.eta).toLocaleDateString().split('/');
        const [etaHr, etaMin, etaSec] = new Date(value.eta).toLocaleTimeString().split(':');
        this.$set(this.operationBaseFieldsForm, 'eta', `${etaYear}-${etaMonth}-${etaDay} ${etaHr}:${etaMin}`);
        const [etdDay, etdMonth, etdYear] = new Date(value.etd).toLocaleDateString().split('/');
        const [etdHr, etdMin, etdSec] = new Date(value.etd).toLocaleTimeString().split(':');
        this.$set(this.operationBaseFieldsForm, 'etd', `${etdYear}-${etdMonth}-${etdDay} ${etdHr}:${etdMin}`);
      },
      showVesselInformation(value) {
        this.$api.operation_captain_details.show(value.id)
          .then(response => {
            if (response.operation_captain_detail) {
              this.vesselInfoForm = response.operation_captain_detail;
            } else {
              this.vesselInfoForm = Object.assign({}, this.tmpFormVessel);
            }
          }).catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      handleOperationInit() {
        const value = this.operation;
        if (value.id || value.vessel_id) {
          this.operationBaseFieldsForm = Object.assign({}, value);
          this.preserveForm(value);
          this.setCustomer(this.operationBaseFieldsForm.vessel_id);
          this.showVesselInformation(value);
        } else {
          this.operationBaseFieldsForm = Object.assign({}, this.tmpForm);
          this.$refs.form && this.$refs.form.resetValidation();
          if (this.$refs.eta) this.$refs.eta.clearHandler();
          if (this.$refs.etd) this.$refs.etd.clearHandler();
        }
        this.populateDropdown();
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
      isDraft(value) {
        console.log('isDraft: ', value);
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>