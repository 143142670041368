<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row v-for="(cashToMaster, index) in cashToMasters" :key="'cash-to-master-' + index">
        <v-col cols="12" class="pb-0">
          <span class="text-h6">Cash To Master: #{{index + 1}}</span>
          <v-btn color="red" icon @click="cashToMasters.splice(index, 1)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Cash Amount"
            placeholder="Enter Cash Amount"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="cashToMaster.amount"
            prepend-inner-icon="mdi-wallet"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Currency"
            placeholder="Select a currency"
            :rules="[rules.required]"
            outlined
            dense
            v-model="cashToMaster.metadata.currency"
            prepend-inner-icon="mdi-cash"
            :items="currencies"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Percentage(charges)"
            placeholder="Enter percentage"
            type="number"
            :rules="[rules.required, rules.negative]"
            outlined
            dense
            v-model="cashToMaster.metadata.percentage"
            prepend-inner-icon="mdi-percent"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Bank Charges"
            placeholder="Enter bank charges"
            type="number"
            :rules="[rules.negative]"
            outlined
            dense
            v-model="cashToMaster.metadata.bank_charges"
            prepend-inner-icon="mdi-cash"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Supplier"
            placeholder="Select a supplier"
            outlined
            dense
            v-model="cashToMaster.card_id"
            prepend-inner-icon="mdi-account"
            :items="suppliers"
            :rules="[rules.required]"
            :disabled="isDisabled"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="cashToMaster.remarks"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-col cols="12" class="pt-7">
        <v-btn color="primary" @click="addCashToMaster">Add</v-btn>
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
          @click="saveDetails">Save</v-btn>
      </v-col>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _find from 'lodash/find';

  export default {
    name: 's-services-opted-cashtomaster-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      servicesOpted: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        loading: false,
        isDisabled: false,
        form: false,
        rules,
        cashToMasters: [],
        serviceOptForm: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: "Cash To Master",
          metadata: {
            percentage: 1.5,
            currency: 'USD',
            bank_charges: 40
          },
          remarks: '',
        },
        suppliers: [],
        sinodaSupplier: [],
        currencies: ["USD", "SGD", "EURO"]
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const services = _.filter(this.servicesOpted, { bill_type: 'Cash To Master' });
        if (services.length > 0) {
          // Filter out services with null card_id
          const newServices = services.filter(service => service.card_id !== null);
          if (newServices.length > 0) {
            this.cashToMasters = newServices.map(service => ({
              ...service,
              amount: parseFloat(service.amount).toFixed(2)
            }));
          }
        }
      },
      populateSuppliers() {
        this.$api.cards.getCard(this.operation.card_id)
          .then(response => {
            if (!(this.serviceOptForm.metadata.percentage && this.serviceOptForm.metadata.bank_charges)) {
              this.serviceOptForm.metadata.percentage = response.card.ctm_percentage;
              this.serviceOptForm.metadata.bank_charges = response.card.ctm_bank_charges;
            }
          });
        this.$api.service_catalogs.get_suppliers_list('Cash To Master')
          .then((response) => {
            this.suppliers = response.suppliers;
            this.sinodaSupplier = _find(this.suppliers, { name: 'Sinoda' });
            if (this.sinodaSupplier) {
              this.serviceOptForm.card_id = this.sinodaSupplier.id;
              this.isDisabled = true;
            }
            this.serviceOptForm.service_catalog_id = response.service_catalog_id;
            this.addCashToMaster();
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.cashToMasters = this.cashToMasters.map(service => {
          service.service_catalog_id = this.serviceOptForm.service_catalog_id;
          service.operation_id = this.operation.id;

          return service;
        });

        // Loop through cashToMasters array to save each service
        Promise.all([
          ...[...this.cashToMasters].map(service => {
            const apiType = service.id ? "update" : "save_service";
            return this.$api.operation_billed_items[apiType](service, true)
          })
        ]).then(() => {
          this.$store.dispatch('setAlert', {
            status: 'success',
            show: true,
            message: 'Cash to master service updated successfully',
          });
        }).catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        }).finally(() => (this.loading = false));
      },
      addCashToMaster() {
        const newCashToMaster = JSON.parse(JSON.stringify(this.serviceOptForm));
        this.cashToMasters.push(newCashToMaster);
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>