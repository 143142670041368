<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Total No. Of Application"
            placeholder="Enter No. Of Application"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="departureVisaApplication.metadata.no_of_application"
            prepend-inner-icon="mdi-account"
            @input="calculateDepartureVisaPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Price Per Application"
            placeholder="Enter Price Per Application"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="departureVisaApplication.metadata.price_per_application"
            prepend-inner-icon="mdi-currency-usd"
            @input="calculateDepartureVisaPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Service Charges"
            placeholder="Enter Service Charges"
            outlined
            dense
            type="number"
            v-model="departureVisaApplication.amount"
            prepend-inner-icon="mdi-currency-usd"
            :hint="departureVisaHintText"
            :rules="amountRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Supplier"
            placeholder="Select Supplier"
            outlined
            dense
            v-model="departureVisaApplication.card_id"
            prepend-inner-icon="mdi-account"
            :disabled="isDisabled"
            :items="visaApplicationSuppliers"
            :rules="[rules.required]"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="departureVisaApplication.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _find from 'lodash/find';

  export default {
    name: 's-services-opted-crew-change-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        isDisabled: false,
        rules,
        departureVisaApplication: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: 'Visa Application Crew Sign Off',
          remarks: '',
          metadata: {
            no_of_application: 0,
            price_per_application: 0
          }
        },
        visaApplicationSuppliers: [],
        sinodaSupplier: [],
        departure_visa_calculated_amount: 0,
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const departureVisaApplication = _.find(this.servicesOpted, {bill_type: 'Visa Application Crew Sign Off'});
        if (departureVisaApplication?.card_id) {
          this.departureVisaApplication = departureVisaApplication;
          this.departureVisaApplication.amount = parseFloat(this.departureVisaApplication.amount).toFixed(2);
        }
      },
      calculateDepartureVisaPrice() {
        this.departure_visa_calculated_amount = parseInt(this.departureVisaApplication.metadata.no_of_application) * parseInt(this.departureVisaApplication.metadata.price_per_application);
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Visa Application Crew Sign Off')
          .then((response) => {
            this.visaApplicationSuppliers = response.suppliers;
            this.sinodaSupplier = _find(this.visaApplicationSuppliers, { name: 'Sinoda' });
            if (this.sinodaSupplier) {
              this.departureVisaApplication.card_id = this.sinodaSupplier.id;
              this.isDisabled = true;
            }
            this.departureVisaApplication.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.departureVisaApplication = Object.assign(this.departureVisaApplication, {
          operation_id: this.operation.id
        });

        Promise.all([
            this.$api.operation_billed_items.save_service(this.departureVisaApplication)
          ]).then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Visa Application Crew Sign Off service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      }
    },
    computed: {
      amountRules() {
        if (this.isDraft || (this.operation && this.operation.is_draft)) {
          return [this.rules.required, this.rules.negative];
        } else {
          return [this.rules.negative];
        }
      },
      departureVisaHintText() {
        if (Number(this.departureVisaApplication.amount) != this.departure_visa_calculated_amount) {
          return `Service charges differs from calculated amount (${this.departure_visa_calculated_amount})`;
        } else {
          return "";
        }
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>