const rules = {
  confirm: conf => v => (v === conf) || 'Does not match',
  negative: v => !(String(v).match(/^-/)) || 'Negative value is not accepted',
  required: v => !!(v && v.toString().trim()) || 'This field is required',
  name: v => (!!(String(v).match(/^(?!.*  )[a-zA-Z \-]{3,50}$/))) || 'Please enter a valid Name',
  mobile: v => (!!(String(v).match(/^(\+\d{1,3}[- ]?)?\d{6,15}$/))) || 'Please enter a valid Mobile Number',
  email: v => !!(String(v || '').match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) || 'Please enter a valid Email',
  length: (len, max) => v => ((v.trim() || '').length >= len && (v.trim() || '').length <= max) || `Invalid character length, required ${len} - ${max}`,
  otp: v => !!(String(v).match(/^\d{6}$/)) || `Please enter a valid 6 digit OTP`,
  password: v => !!(String(v).match(/^(?!.* )(?=.*\w).{8,15}$/)) || 'Please enter a valid password, only 8 - 15 characters',
};
  
export default rules;