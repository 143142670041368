<template>
  <v-container fluid class="mt">
    <v-row>
      <v-col cols="12" sm="6" md="3" lg="3"
        v-for="(list, index) in navList" :key="index" v-if="list.access">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card :elevation="hover ? 6 : 2" rounded :class="{ 'on-hover': hover }">
            <v-card-title class="text-center justify-center secondary">
              <v-icon>{{list.iconList[0]}}</v-icon>
              <v-icon class="px-7">{{list.iconList[1]}}</v-icon>
              <v-icon class="pr-7">{{list.iconList[2]}}</v-icon>
              <v-icon>{{list.iconList[3]}}</v-icon>
            </v-card-title>
            <v-card-title class="text-center justify-center accent">
              <v-btn @click="handleNavigation(list)" text>{{list.title}}</v-btn>
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 's-home',
    data() {
      return {
        navList: [
          {title: 'Dashboard', iconList: ['mdi-chart-line', 'mdi-chart-bar', 'mdi-chart-pie', 'mdi-chart-histogram'], access: this.$root.hasAccess('')},
          {title: 'Port Calls', iconList: ['mdi-file-document', 'mdi-ferry', 'mdi-gas-station', 'mdi-anchor'], access: true},
          {title: "Contacts", iconList: ['mdi-cards-outline', 'mdi-contacts', 'mdi-account-multiple', 'mdi-sign-text'], access: true},
          {title: 'Vessels', iconList: ['mdi-ferry', 'mdi-compass', 'mdi-anchor', 'mdi-earth'], access: true},
          {title: 'Voyage Tracker', iconList: ['mdi-ferry', 'mdi-map-marker', 'mdi-timetable', 'mdi-anchor'], access: true},
          {title: 'Service Catalogs', iconList: ['mdi-cards-variant', 'mdi-tag-text-outline', 'mdi-tag-plus', 'mdi-tag-remove'], access: this.$root.hasAccess('')},
          {title: 'Service Catalog Pricings', iconList: ['mdi-credit-card-plus', 'mdi-account-check', 'mdi-cash-100', 'mdi-wallet'], access: this.$root.hasAccess('')},
          {title: 'Users', iconList: ['mdi-account', 'mdi-account-plus', 'mdi-account-edit', 'mdi-account-remove'], access: this.$root.hasAccess('')},
        ],
      };
    },
    methods: {
      handleNavigation(navigate) {
        this.$router.push({name: navigate.title.toLocaleLowerCase()});
      },
    },
  };
</script>