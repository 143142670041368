import http from '../axios';

export function index(operation_id) {
  return http.get(`/operation_billed_items?operation_id=${operation_id}`);
}

export function save_service(operation_billed_item, multiple = false) {
  return http.post('/operation_billed_items/save_service', {operation_billed_item, multiple});
}

export function destroy(item,operation_id) {
  return http.delete('/operation_billed_items/destroy', { params: { item, operation_id } });
}

export function billed_items_checklist(operation_id) {
  return http.get(`/operation_billed_items/billed_items_checklist?operation_id=${operation_id}`);
}

export function update(payload) {
  return http.patch(`/operation_billed_items/${payload.id}`, payload);
}

export function upload_receipts(formData) {
  return http.post('/operation_billed_items/upload_receipts', formData);
}

export function remove_receipt(payload) {
  return http.post('/operation_billed_items/remove_receipt', payload);
}

export function createService(requestData){
  return http.post('/operation_billed_items/create_service', requestData);
}

export function get_services_list(operation_id){
  return http.get('/operation_billed_items/get_services_list', { params: { operation_id}});
}
