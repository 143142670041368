<template>
  <v-row class="text-left">
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-gmail</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Captain Email
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.captain_mail_id}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-phone</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Vessel Contact
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.vessel_contact}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-arrow-bottom-right</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Coming From
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.coming_from}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-arrow-top-right</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Going To
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.going_to}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-arrow-bottom-right</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Last Port
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.last_port}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-arrow-top-right</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Next Port
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.next_port}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-format-align-top</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Forward
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.fwd}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-format-align-bottom</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          AFT
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.aft}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-format-align-middle</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Total Height of Ship
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.ht}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-arrow-expand-up</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Freeboard
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.freeboard}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-select-all</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Cargo Type
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.cargo_type}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-select-inverse</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Quantity / Volume(Metric Ton)
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.qty_volume}}
        </v-col>
      </v-row>
    </v-col>
        <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-flag</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Country
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.flag}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-file-tree</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Vessel Type
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.vessel_type}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-book</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Call Sign
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.call_sign}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-10">
      <span class="text-h6">Bunker Details</span>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-omega</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Grade
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.bunker.grade}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-filter</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Fuel Oil Intake Rate / Hr
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.bunker.oil_intake_rate}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-gas-station</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          MGO
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.bunker.mgo}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-filter</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Gas Oil Intake Rate
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.bunker.gas_intake_rate}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          2 Grades Together?
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.bunker.two_grade_together ? 'Yes' : 'No'}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-water</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Freshwater Quantity
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.bunker.freshwater_quantity}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-cup-water</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Freshwater Intake Rate
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{vesselInfo.bunker.freshwater_intake_rate}}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 's-show-vessel-information',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      vesselInfo: {
        type: Object,
        default: {},
      },
    },
  };
</script>

<style scoped>
  .job-row__border {
    border-bottom: 1px solid lightgray;
  }
</style>