import http from '../axios';

export function index() {
  return http.get('/service_catalogs');
}

export function update(payload) {
  return http.patch(`/service_catalogs/${payload.id}`, payload);
}

export function create(payload){
  return http.post('/service_catalogs', payload);
}

export function get_suppliers_list(service_name) {
  return http.get(`/service_catalogs/get_suppliers_list?service_name=${service_name}`);
}

export function getServiceCatalogName() {
  return http.get('/service_catalogs/get_service_catalog_name');
}