<template>
  <v-container fluid>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab bottom right fixed color="primary"
          v-bind="attrs" v-on="on" @click="dialogForm = {action: 'Create Service Catalog Pricing', showForm: true, service_catalog_pricing: {}}">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Create Service Catalog Pricing</span>
    </v-tooltip>
    <v-btn class="mb-5" color="primary" @click="supplyBoat()">Supply Boat</v-btn>
    <v-btn class="mb-5" color="primary" @click="passengerBoat()">Passenger Boat</v-btn>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :no-data-text="'No data found'"
          :no-results-text="'No results found'"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon @click="dialogForm = {action: 'Edit Service Catalog Pricing', showForm: true, service_catalog_pricing: Object.assign(item)}">
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.version="{ item }">
          <v-btn>
            Version History
          </v-btn>
        </template>
        <template v-slot:item.customer_name="{ item }">
          <span>{{ item.customer_name ? item.customer_name : 'Base Price'}}</span>
        </template>
        <template v-slot:item.metadata="{ item }">
          <div v-html="formatMetadata(item.metadata)"></div>
        </template>
        <template v-slot:progress>
          <div class="full-width text-center ma-4">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <service-catalog-pricing-form 
      :service_catalog_pricing="dialogForm.service_catalog_pricing"
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      :services="services"
      :suppliers="suppliers"
      @formSubmitted="resetDialog();getServiceCatalogPricings();"
      @closeDialog="resetDialog()" />
  </v-container>
</template>

<script>
  import ServiceCatalogPricingForm from './blocks/ServiceCatalogPricingForm';
  import { PERSON_OPTIONS } from '../../services/constants/constants';
  import { FRESHWATER_OPTIONS } from '../../services/constants/constants';
  import { CBM_OPTIONS } from '../../services/constants/constants';

  export default {
    name: 's-service-catalog-pricing',
    components: {
      ServiceCatalogPricingForm,
    },
    data() {
      return {
        dialogForm: {
          showForm: false,
          action: '',
          service_catalog_pricing: {},
        },
        search: '',
        loading: false,
        headers: [
          {text: 'Edit', value: 'edit', align: 'start'},
          {text: 'Id', value: 'id', align: 'center'},
          {text: 'Service', value: 'service_name', align: 'center'},
          {text: 'Metadata', value: 'metadata', align: 'center'},
          {text: 'Price', value: 'price', align: 'center'},
          {text: 'Supplier', value: 'card_name', align: 'center'},
        ],
        items: [],
        services: [],
        suppliers: []
      };
    },
    methods: {
      getPersons(value) {
        const person = PERSON_OPTIONS.find(person => person.value === value);
        return person ? person.name : value;
      },
      getQuantity(value) {
        const quantity = FRESHWATER_OPTIONS.find(quantity => quantity.value === value);
        return quantity ? quantity.name : value;
      },
      getCBM(value) {
        const cbm = CBM_OPTIONS.find(cbm => cbm.value === value);
        return cbm ? cbm.name : value;
      },
      resetDialog() {
        this.dialogForm = {showForm: false, action: '', service_catalog_pricing: {}};
      },
      formatMetadata(metadata) {
        if (!metadata || typeof metadata !== 'object') return '';
        if (metadata.persons) {
          const personName = this.getPersons(metadata.persons);
          metadata.persons = personName;
        }
        if (metadata.quantity) {
          const freshwaterQuantity = this.getQuantity(metadata.quantity);
          metadata.quantity = freshwaterQuantity;
        }
        if (metadata.cbm) {
          const cbm = this.getCBM(metadata.cbm);
          metadata.cbm = cbm;
        }
        return Object.entries(metadata)
          .map(([key, value]) => `${this.capitalizeFirstLetter(key)}: ${value}`)
          .join('<br>');
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      getServiceCatalogPricings() {
        this.loading = true;
        this.$api.service_catalog_pricings.index()
          .then((response) => {
            this.items = response.service_catalog_pricings;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getServiceCatalogs() {
        this.loading = true;
        this.$api.service_catalogs.index()
          .then((response) => {
            this.services = response.service_catalogs;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      populateCards(field, card_type) {
        this.$api.cards.index(card_type)
          .then((response) => {
            this[field] = response.cards;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      supplyBoat() {
        this.$router.push({
          name: 'SupplyBoat'
        });
      },
      passengerBoat() {
        this.$router.push({
          name: 'PassengerBoat'
        });
      },
    },
    created() {
      if (!this.$root.hasAccess('')) {
        this.$store.dispatch('setAlert', {
          status: 'error',
          show: true,
          message: "Please contact admin to access this page",
        });
        this.$router.push({name: 'home'});
      }
      this.populateCards('suppliers', 'supplier');
      this.getServiceCatalogs();
      this.getServiceCatalogPricings();
    },
  };
</script>