<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-row v-for="(supplyBoat, index) in supplyBoats" :key="'supply-' + index">
          <v-col cols="12" class="pb-0">
            <span class="text-h6">Supply Boat: #{{index + 1}}</span>
            <v-btn color="red" icon @click="supplyBoats.splice(index, 1)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
            <v-text-field
              label="Supply Boat Service Charges"
              placeholder="Enter Supply Charges Service Charges"
              outlined
              dense
              type="number"
              v-model="supplyBoat.amount"
              prepend-inner-icon="mdi-currency-usd"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-text-field
              label="No.of Pallets"
              placeholder="Enter No.of Pallets"
              :rules="[rules.negative]"
              outlined
              dense
              type="number"
              v-model="supplyBoat.metadata.pallets"
              prepend-inner-icon="mdi-shipping-pallet"
              @change="onFieldChange(supplyBoat)"
            ></v-text-field>
          </v-col>
          <v-col v-if="supplyBoat.metadata.pallets <= 20" cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-text-field
              label="No.of Hours"
              placeholder="Enter No.of Hours"
              :rules="[rules.negative]"
              outlined
              dense
              type="number"
              v-model="supplyBoat.metadata.no_of_hours"
              prepend-inner-icon="mdi-hours-24"
              @change="onFieldChange(supplyBoat)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Sector"
              placeholder="Select Sector"
              outlined
              dense
              v-model="supplyBoat.metadata.sector"
              prepend-inner-icon="mdi-anchor"
              :items="sectors"
              :rules="amountRules"
              @change="onSectorChange(supplyBoat)"
            ></v-select>
          </v-col>
          <v-col v-if="supplyBoat.metadata.sector === 'IPL'" cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Launch Location"
              placeholder="Filter by Launch Location"
              outlined
              dense
              :items="loadLaunchLocation(supplyBoat.metadata.sector)"
              v-model="supplyBoat.metadata.launch_location"
              prepend-inner-icon="mdi-anchor"
              clearable
              @change="onFieldChange(supplyBoat)"
            ></v-select>
          </v-col>
          <v-col v-if="supplyBoat.metadata.sector === 'IPL'" cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Anchorage Location"
              placeholder="Filter by Anchorage Location"
              outlined
              dense
              :items="loadAnchorageLocation(supplyBoat.metadata.sector)"
              v-model="supplyBoat.metadata.anchorage_location"
              prepend-inner-icon="mdi-anchor"
              clearable
              @change="onFieldChange(supplyBoat)"
            ></v-select>
          </v-col>
          <v-col v-if="supplyBoat.metadata.sector === 'OPL'" cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="OPL Sub Sectors"
              placeholder="Filter by OPL Sub Sectors"
              outlined
              dense
              :items="loadSubSectors(supplyBoat.metadata.sector)"
              v-model="supplyBoat.metadata.subSector"
              prepend-inner-icon="mdi-anchor"
              clearable
              @change="onFieldChange(supplyBoat)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Supply Boat Supplier"
              placeholder="Select supply boat supplier"
              outlined
              dense
              v-model="supplyBoat.card_id"
              prepend-inner-icon="mdi-account"
              :items="supplySuppliers"
              :rules="[rules.required]"
              item-text="name"
              item-value="id"
              @change="onFieldChange(supplyBoat)"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-10">
            <v-textarea
              label="Remarks"
              placeholder="Enter Remarks"
              outlined
              dense
              hide-details
              :rows="2"
              v-model="supplyBoat.remarks"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="addSupplyBoat">Add</v-btn>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _ from 'lodash';

  export default {
    name: 's-services-opted-launch-boat',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        supplyBoats: [],
        serviceOptFormSupply: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: 'Launch Boat Supply Boat',
          remarks: '',
          metadata: {
            no_of_hours: 0,
            pallets: 0,
            launch_location: "",
            anchorage_location: "",
            sector: "",
            subSector: "",
          }
        },
        supplySuppliers: [],
        pricings: [],
        supplyServiceCatalogId: null,
        sectors: ["IPL", "OPL"]
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      onFieldChange(supplyBoat) {
        if (supplyBoat.metadata.pallets > 20) {
          supplyBoat.metadata.no_of_hours = 0;
        }
        this.calculateAmount(supplyBoat);
      },
      onSectorChange(supplyBoat) {
        if (supplyBoat.metadata.sector === 'IPL') {
          supplyBoat.metadata.subSector = '';
        }
        this.calculateAmount(supplyBoat);
      },
      addSupplyBoat() {
        const newSupplyBoat = JSON.parse(JSON.stringify(this.serviceOptFormSupply));
        this.supplyBoats.push(newSupplyBoat);
      },
      loadSubSectors(location) {
        if (location === 'OPL') {
          return ['EOPL - 104 18E', 'EOPL - 104 21E', 'HORSBURG - 104 18E', 'HORSBURG - 104 21E', 'South China Sea', 'WOPL - 103 18E'];
        }
        return [];
      },
      loadLaunchLocation(location) {
        if (location === 'IPL') {
          return ['Penjuru Terminal', 'Marina South Wharves'];
        }
        return [];
      },
      loadAnchorageLocation(location) {
        if (location === 'IPL') {
          return ['Western Working', 'Western Petroleum A, B', 'Sudong Special Purpose', 'Raffles', 'Very Large Crude Carrier', 'Eastern Working', 'Eastern Petroleum A, B', 'Eastern Special Purpose A, B', 'Eastern Bunkering C', 'Eastern Bunkering B, A', 'Changi General Purpose'];
        }
        return [];
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const supplyServices = _.filter(this.servicesOpted, { bill_type: 'Launch Boat Supply Boat' });
        if (supplyServices.length) {
          const newServices = supplyServices.filter(service => service.card_id !== null);
          if (newServices.length > 0) {
            this.supplyBoats = newServices.map(service => ({
              ...service,
              amount: parseFloat(service.amount).toFixed(2)
            }));
          } else {
            this.supplyBoats = [JSON.parse(JSON.stringify(this.serviceOptFormSupply))];
          }
        } else {
          this.addSupplyBoat();
        }
      },
      populateSuppliers() {
        Promise.all([
          this.$api.service_catalogs.get_suppliers_list('Launch Boat Supply Boat')
            .then((response) => {
              this.supplySuppliers = response.suppliers;
              this.supplyServiceCatalogId = response.service_catalog_id;
            })
        ]).then(() => {
          this.initServiceOptedDetails();
          this.getSupplyBoatPricing();
        }).catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        });
      },
      getSupplyBoatPricing() {
        this.loading = true;

        this.$api.supply_boat_pricings.index()
          .then((response) => {
            this.pricings = response.supply_boat_pricing;
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => {
            this.loading = false;
          });
      },
      calculateAmount(supplyBoat) {
        if (!supplyBoat.card_id || supplyBoat.metadata.pallets === undefined || supplyBoat.metadata.no_of_hours === undefined) {
          supplyBoat.amount = 0;
          return;
        }

        const palletThreshold = this.getPalletThreshold(supplyBoat.metadata.pallets);
        const hoursThreshold = this.getHoursThreshold(supplyBoat.metadata.no_of_hours);
        const sector = supplyBoat.metadata.sector;
        const subSector = supplyBoat.metadata.subSector;
        const launch_location = supplyBoat.metadata.launch_location;
        const anchorage_location = supplyBoat.metadata.anchorage_location;
        const card_id = supplyBoat.card_id;

        let basePrice = 0;
        let fuelSurcharge = 0;

        if (supplyBoat.metadata.no_of_hours > 4) {
            const basePricing = this.getPriceForHours(4, card_id, palletThreshold, sector, subSector, launch_location, anchorage_location);
            const additionalPricing = this.getPriceForHours(5, card_id, palletThreshold, sector, subSector, launch_location, anchorage_location);
            basePrice = basePricing.price;
            fuelSurcharge = basePricing.fuel_surcharge;

            const additionalPrice = additionalPricing.price * (supplyBoat.metadata.no_of_hours - 4);
            basePrice += additionalPrice;
        } else {
            const pricing = this.getPriceForHours(hoursThreshold, card_id, palletThreshold, sector, subSector, launch_location, anchorage_location);
            basePrice = pricing.price;
            fuelSurcharge = pricing.fuel_surcharge;
        }

        supplyBoat.amount = (basePrice + fuelSurcharge).toFixed(2);
      },
      getPriceForHours(hours, card_id, pallets, sector, subSector, launch_location, anchorage_location) {
        const pricing = this.pricings.find(pricing => {
          let matches = pricing.card_id === card_id &&
                        pricing.sector === sector &&
                        pricing.pallets === pallets;

          if (hours) {
            matches = matches && pricing.hours === hours;
          }
          if (sector === 'OPL') {
            matches = matches && pricing.sub_sector === subSector;
          }
          if (sector === 'IPL') {
            matches = matches &&
              pricing.launch_location === launch_location &&
              pricing.anchorage_location === anchorage_location;
          }
          return matches;
          });

        if (!pricing) {
          return { price: 0, fuel_surcharge: 0 };
        }
        return {
          price: parseFloat(pricing.price) || 0,
          fuel_surcharge: parseFloat(pricing.fuel_surcharge) || 0
        };
      },
      getPalletThreshold(pallets) {
        if (pallets >= 21) return 21;
        if (pallets >= 11) return 20;
        if (pallets >= 1) return 10;
        return 0;
      },
      getHoursThreshold(hours) {
        if (hours >= 5) return 5;
        if (hours >= 1) return 4;
        return 0;
      },
      saveDetails() {
        if (!this.submit()) return;
        this.loading = true;

        this.supplyBoats = this.supplyBoats.map(service => {
          service.service_catalog_id = this.supplyServiceCatalogId;
          service.operation_id = this.operation.id;

          return service;
        });
        Promise.all([
          ...[...this.supplyBoats].map(service => {
            const apiType = service.id ? "update" : "save_service";
            return this.$api.operation_billed_items[apiType](service, true)
          })
        ]).then(() => {
          this.$store.dispatch('setAlert', {
            status: 'success',
            show: true,
            message: 'Launch Boat service updated successfully',
          });
        }).catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        }).finally(() => (this.loading = false));
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      }
    },
    computed: {
      amountRules() {
        if (this.isDraft || (this.operation && this.operation.is_draft)) {
          return [];
        } else {
          return [this.rules.required];
        }
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>
