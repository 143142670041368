<template>
	<v-container fluid>
		<v-card>
			<v-data-table
      	:headers="formattedHeaders"
      	:items="items"
      	:search="search"
      	:loading="loading"
      	:sort-by="['id']"
      	:sort-desc="[true]"
    	>
    		<template v-slot:item.id="{ item }">
        	<v-btn :loading="completedLoading == item.id" :disabled="completedLoading == item.id" text @click="$router.push({name: 'showOperationReport', params: {id: item.id}})">
          	<span><u>{{item.id}}</u></span>
        	</v-btn>
      	</template>
      	<template v-slot:item.status="{ item }">
        	{{item.status}}
      	</template>
      	<template v-slot:item.edit="{ item }">
        	<v-btn icon @click="dialogForm = {action: 'Edit Job', showForm: true, isDraft: item.is_draft};currentOperation=Object.assign({}, item)">
          	<v-icon>mdi-account-edit</v-icon>
        	</v-btn>
      	</template>
      	<template v-slot:item.complete="{ item }">
        	<v-btn color="success" small @click="markJobComplete(item)">
          	Mark As Billed
        	</v-btn>
      	</template>
    	</v-data-table>
		</v-card>
		<operation-form 
      :operation="currentOperation"
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      :isDraft="dialogForm.isDraft"
      @closeDialog="resetDialog()"
      @completeJob="markJobComplete($event)"
      @showOperation="viewJob = true;currentOperation=Object.assign({}, $event)" />
	</v-container>
</template>

<script>
  import OperationForm from '../operations/blocks/OperationForm';

  export default {
    name: 's-operations',
    components: {
      OperationForm,
    },
    data() {
      return {
        showCompleted: false,
        dialogForm: {
          showForm: false,
          action: '',
        },
        completedLoading: null,
        viewJob: false,
        currentOperation: {},
        search: '',
        loading: false,
        headers: [
          {text: 'Id', value: 'id', align: 'start'},
          {text: 'Job Scope', value: 'job_scope', align: 'center'},
          {text: 'status', value: 'status', align: 'center'},
          {text: 'Job Start Date', value: 'job_start_date', align: 'center'},
          {text: 'Incharge', value: 'supervisor_name', align: 'center'},
          {text: 'Vessel', value: 'vessel_name', align: 'center'},
          {text: 'Customer', value: 'customer_name', align: 'center'},
        ],
        items: [],
      };
    },
    computed: {
      formattedHeaders() {
        return [
          ...this.headers,
          {text: 'Edit', value: 'edit', align: 'center'},
          {
            text: 'Mark Job Complete',
            value: 'complete',
            align: 'center',
          },
        ];
      },
    },
    methods: {
      markJobComplete(operation) {
        if (this.completedLoading == operation.id) return;

        this.completedLoading = operation.id;
        if (confirm("Completed jobs cannot be edited, Are you sure to complete this job?")) {
          operation.status = 'Completed';
          this.$api.operations.create(operation)
            .then(response => {
              this.$store.dispatch('setAlert', {
                show: true,
                message: `Job#${operation.id} marked as completed`,
              });
              this.getOperations();
              this.resetDialog();
            }).catch(err => {
              this.$store.dispatch('setAlert', {
                status: 'error',
                show: true,
                message: err.message,
              });
            }).finally(() => (this.completedLoading = null));
        } else {
          this.completedLoading = null;
        }
      },
      resetDialog() {
        this.dialogForm = {showForm: false, action: '',isDraft: false};
        this.getOperations();
      },
      getOperations() {
        this.loading = true;
        this.$api.operations.index()
          .then((response) => {
            this.items = response.operations.filter(x => !x.is_draft && x.status === 'Open');;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      }
    },
    created() {
      this.getOperations();
    },
  };
</script>