<template>
	<div class="text-center">
    <v-dialog v-model="showForm"
    	fullscreen
    	hide-overlay
      persistent
    	transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-stepper alt-labels v-model="currentStep">
        	<v-stepper-header>
		        <v-stepper-step step="1" :key="1" :complete="!!currentOperation.id" editable>
		          Job Details
		        </v-stepper-step>
		        <v-divider></v-divider>
		        <v-stepper-step step="2" :key="2" :complete="operation.approval_closure" :editable="!!currentOperation.id">
			        Services
		        </v-stepper-step>
		        <v-divider></v-divider>
		        <v-stepper-step step="3" :key="3" :complete="operation.approval_closure" :editable="!!currentOperation.id">
		        	Services Opted
		        </v-stepper-step>
            <v-divider v-if="(this.$root.hasAccess('can_manage_fda')) && (!isDraft)"></v-divider>
            <v-stepper-step v-if="(this.$root.hasAccess('can_manage_fda')) && (!isDraft)" step="4" :key="4" :complete="operation.approval_closure" :editable="!!currentOperation.id">
              Approval
            </v-stepper-step>
		        <v-divider v-if="(this.$root.hasAccess('can_manage_fda')) && (!isDraft)"></v-divider>
		        <v-stepper-step v-if="(this.$root.hasAccess('can_manage_fda')) && (!isDraft)" step="5" :key="5" :editable="!!currentOperation.id">
              FDA
		        </v-stepper-step>
		      </v-stepper-header>

		      <v-stepper-items>
	          <v-stepper-content :key="1" step="1" class="px-0">
              <div class="headline pl-4 display-title">Job Details</div>
	          	<job-details @nextStep="nextStep" :isDraft="isDraft" :operation="currentOperation" />
	          </v-stepper-content>
	          <v-stepper-content :key="2" step="2" class="px-0">
              <div class="headline pl-4 display-title">Services</div>
	            <services @nextStep="handleNextStep" :isDraft="isDraft" :operation="currentOperation" />
	          </v-stepper-content>
	          <v-stepper-content :key="3" step="3" class="px-0">
              <div class="headline pl-4 display-title">Services Opted</div>
	            <services-opted @toggleShow="toggleShow" @nextStep="handleNextStep" ref="serviceOpted" :operation="currentOperation" :isDraft="isDraft" @dialogClose="$emit('closeDialog')"/>
	          </v-stepper-content>
            <v-stepper-content v-if="(this.$root.hasAccess('can_manage_fda')) && (!isDraft)" :key="4" step="4" class="px-0">
              <div class="headline pl-4 display-title">Approval</div>
              <approval @toggleShow="toggleShow" @nextStep="handleNextStep" ref="approval" :operation="currentOperation" :isDraft="isDraft" @dialogClose="$emit('closeDialog')"/>
            </v-stepper-content>
	          <v-stepper-content v-if="(this.$root.hasAccess('can_manage_fda')) && (!isDraft)" :key="5" step="5" class="px-0">
              <div class="headline pl-4 display-title">Receipts</div>
	            <receipts @nextStep="nextStep" ref="receipts" @completeJob="$emit('completeJob', $event)" :operation="currentOperation" />
	          </v-stepper-content>
	        </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
	import JobDetails from './JobDetails';
  import Services from './Services';
	import ServicesOpted from './ServicesOpted';
	import Receipts from './Receipts';
  import Approval from './Approval';

	export default {
		name: 's-operation-form',
		props: {
      action: {
        type: String,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      operation: {
        type: Object,
        default: {},
      },
    },
    components: {
    	JobDetails,
      Services,
    	ServicesOpted,
    	Receipts,
      Approval
    },
    data() {
    	return {
        currentOperation: {},
    		currentStep: 1
    	};
    },
    methods: {
    	nextStep(step,operation) {
    		this.currentStep = step;
        this.currentOperation = operation;
    	},
      toggleShow(operation) {
        this.$emit('closeDialog');
        this.$emit('showOperation', operation);
      },
      handleNextStep(step) {
        this.currentStep = step;
        if (step === 3) {
          this.$refs.serviceOpted.handleOperationInit();
        } else if (step === 4) {
          this.$refs.approval.handleOperationInit();
        } else if (step === 5) {
          this.$refs.receipts.handleOperationInit();
        }
      },
    },
    watch: {
      operation(value) {
        this.currentOperation = Object.assign({},this.operation);
        if (!value.id) this.currentStep = 1;
        else {
          this.currentStep = 1;
          if (value.approval_closure && !value.is_draft) this.currentStep = 5;
        }
      },
    	currentStep(value) {
    		console.log(value);
    	},
    },
    created() {
      this.currentOperation = Object.assign({},this.operation);
    },
	}
</script>

<style scoped>
  >>> .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 0px !important;
  }
  .display-title {
    display: none;
  }
  @media only screen and (max-width: 959px) {
    .display-title {
      display: block;
    }
  }
  >>> .v-stepper__label {
    text-align: center !important;
  }
</style>