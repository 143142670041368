<template>
  <v-row class="text-left">
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-drag</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Job Id
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.job_name}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-script</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Job Scope
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.job_scope}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-account</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Customer
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.customer_name}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-ferry</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Vessel Name
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.vessel_name}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-account</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Incharge
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.supervisor_name}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-select</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Status
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.status}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-calendar</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Job Start Date
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.job_start_date}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-calendar</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          ETA
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.eta | formatDate}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-calendar</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          ETD
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.etd | formatDate}}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="d-flex py-0">
      <v-row class="job-row__border ma-1 align-center">
        <v-col cols="1" class="primary--text">
          <v-icon color="primary">mdi-menu</v-icon>
        </v-col>
        <v-col cols="5" class="primary--text subtitle-2 font-weight-bold py-0">
          Remarks
        </v-col>
        <v-col cols="6" class="subtitle-2 py-0">
          {{operation.remarks_arrival}}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 's-show-job-details',
    props: {
      operation: {
        type: Object,
        default: {},
      },
    },
    filters: {
      formatDate(date) {
        const [day, month, year] = new Date(date).toLocaleDateString().split('/');
        const [hr, min, sec] = new Date(date).toLocaleTimeString().split(':');
        return `${year}-${month}-${day}`;
      }
    },
  };
</script>

<style scoped>
  .job-row__border {
    border-bottom: 1px solid lightgray;
  }
</style>