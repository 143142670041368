<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="form" @submit.prevent="submit">
          <v-card-text class="pb-0">
            <v-select
              label="Launch Location"
              placeholder="Select a Launch Location"
              outlined
              :rules="[rules.required]"
              dense
              v-model="passengerBoatPricingForm.launch_location"
              prepend-inner-icon="mdi-map-marker"
              :items = "launchLocation"
            ></v-select>
            <v-select
              label="Anchorage Location"
              placeholder="Select a Anchorage Location"
              outlined
              :rules="[rules.required]"
              dense
              v-model="passengerBoatPricingForm.anchorage_location"
              prepend-inner-icon="mdi-anchor"
              :items = "anchorageLocation"
            ></v-select>
            <v-select
              v-show="passengerBoatPricingForm.anchorage_location"
              label="Anchorage SubSector"
              placeholder="Select a Anchorage Subsector"
              outlined
              :rules="[rules.required]"
              dense
              v-model="passengerBoatPricingForm.sub_sector"
              prepend-inner-icon="mdi-map-marker-plus"
              :items = "loadSubSectors(passengerBoatPricingForm.anchorage_location)"
            ></v-select>
            <v-select
              label="Day / Night"
              placeholder="Select a Day / Night"
              outlined
              :rules="[rules.required]"
              dense
              v-model="passengerBoatPricingForm.day"
              prepend-inner-icon="mdi-map-marker"
              :items = "day"
            ></v-select>
            <v-text-field
              label="Price"
              placeholder="Enter Price"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              v-model="passengerBoatPricingForm.price"
              prepend-inner-icon="mdi-currency-usd"
              clearable
            ></v-text-field>
            <v-text-field
              label="Fuel Surcharge"
              placeholder="Enter Fuel Surcharge"
              :rules="[rules.required]"
              type="number"
              outlined
              dense
              v-model="passengerBoatPricingForm.fuel_surcharge"
              prepend-inner-icon="mdi-currency-usd"
              clearable
            ></v-text-field>
            <v-select
              label="Supplier"
              placeholder="Select a supplier"
              :rules="[rules.required]"
              outlined
              dense
              v-model="passengerBoatPricingForm.card_id"
              prepend-inner-icon="mdi-account"
              :items="suppliers"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="primary" outlined @click="formSubmit()" 
              :disabled="loading" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '../../../utilities/validator';

  export default {
    name: 's-passenger-boat-form',
    props: {
      action: {
        type: String,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      suppliers: Array,
      passenger_boat_pricing: {
        type: Object,
        default: {},
      },
    },
    data() {
      let passengerBoatPricingForm = {
        launch_location: '',
        anchorage_location: '',
        sub_sector: '',
        day: '',
        price: '',
        fuel_surcharge: '',
        card_id: ''
      };

      return {
        form: false,
        rules,
        loading: false,
        launchLocation: ['Marina South Pier', 'West Coast Pier'],
        anchorageLocation: ['Eastern Sector', 'Western Sector', 'Jurong Sector'],
        day: ["Day", "Night"],
        subSectorItems: {
          "Eastern Sector": ["Changi Barge Temporary Holding Anchorage", "Changi General Purposes Anchorage", "Man-of-War Anchorage", "Eastern Bunkering A Anchorage", "Eastern Bunkering B Anchorage", "Eastern Petroleum C Anchorage", "Small Craft B Anchorage", "Small Craft A Anchorage", "Eastern Petroleum B Anchorage", "Eastern Special Purposes A Anchorage", "Eastern Bunkering C Anchorage", "Eastern Holding A Anchorage", "Eastern Petroleum A Anchorage", "Eastern Anchorage", "Eastern Holding B Anchorage", "Eastern Holding C Anchorage"],
          "Western Sector": ["Western Quarantine and Immigration Anchorage", "Western Anchorage", "Western Petroleum A Anchorage", "Western Holding Anchorage", "Western Petroleum B Anchorage", "Raffles Reserved Anchorage", "Raffles Petroleum Anchorage", "Selat Pauh Anchorage", "Selat Pauh Petroleum Anchorage"],
          "Jurong Sector": ["Sudong Petroleum Holding Anchorage", "Sudong Explosive Anchorage", "Sudong Special Purpose Anchorage", "Sudong Holding Anchorage", "Very Large Crude Carrier Anchorage"]
        },
        tmpForm: Object.assign({}, passengerBoatPricingForm),
        passengerBoatPricingForm: Object.assign({}, passengerBoatPricingForm),
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      formSubmit() {
        if(!this.submit()) return;
        this.loading = true;
        if (this.passengerBoatPricingForm.id) {
          this.updateForm();
        } else {
          this.saveService();
        }
      },
      updateForm() {
        this.$api.passenger_boat_pricings.update(this.passengerBoatPricingForm)
          .then((response) => {
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Passenger Boat Pricing updated successfully',
            });
          })
          .catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      saveService() {
        this.$api.passenger_boat_pricings.create(this.passengerBoatPricingForm)
          .then((response) => {
            this.passengerBoatPricingForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Passenger Boat Pricing saved successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
    },
    watch: {
      passenger_boat_pricing(value) {
        this.passengerBoatPricingForm = Object.assign({}, value);
        if (!value.id) this.$refs.form && this.$refs.form.reset();
      },
    },
    computed: {
      loadSubSectors() {
        return (anchorageLocation) => {
          return this.subSectorItems[anchorageLocation] || [];
        };
      },
    },
  };
</script>
