<template>
  <v-container fluid>
    <v-tooltip left v-if="$root.hasAccess('can_manage_cards')">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab bottom right fixed color="primary"
          v-bind="attrs" v-on="on" @click="dialogForm = {action: 'Create Contact', showForm: true, card: {}}">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Create Contact</span>
    </v-tooltip>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :no-data-text="'No data found'"
          :no-results-text="'No results found'"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn v-if="$root.hasAccess('can_manage_cards')" icon @click="dialogForm = {action: 'Edit Contact', showForm: true, card: Object.assign(item)}">
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.is_active="{ item }">
          <span>{{ item.is_active ? 'Active' : 'Inactive'}}</span>
        </template>
        <template v-slot:item.role="{ item }">
          <span class="text-capitalize">{{ item.card_type }}</span>
        </template>
        <template v-slot:progress>
          <div class="full-width text-center ma-4">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <card-form 
      :card="dialogForm.card"
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      @formSubmitted="resetDialog();getCards();"
      @closeDialog="resetDialog()" />
  </v-container>
</template>

<script>
  import CardForm from './blocks/CardForm';

  export default {
    name: 's-card',
    components: {
      CardForm,
    },
    data() {
      return {
        dialogForm: {
          showForm: false,
          action: '',
          card: {},
        },
        search: '',
        loading: false,
        headers: [
          {text: 'Id', value: 'id', align: 'start'},
          {text: 'Name', value: 'name', align: 'center'},
          {text: 'Email', value: 'email', align: 'center'},
          {text: 'Mobile', value: 'mobile', align: 'center'},
          {text: 'Contact Type', value: 'card_type', align: 'center'},
          {text: 'Parent Company', value: 'parent_company', align: 'center'},
          {text: 'Is Active', value: 'is_active', align: 'center'},
          {text: 'Address', value: 'address', align: 'center'},
          {text: 'City', value: 'city', align: 'center'},
          {text: 'State', value: 'state', align: 'center'},
          {text: 'Country', value: 'country', align: 'center'},
          {text: 'Postcode', value: 'postcode', align: 'center'},
        ],
        items: [],
      };
    },
    methods: {
      resetDialog() {
        this.dialogForm = {showForm: false, action: '', card: {}};
      },
      getCards() {
        this.loading = true;
        this.$api.cards.index()
          .then((response) => {
            this.items = response.cards;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    created() {
      this.getCards();

      if (this.$root.hasAccess('can_manage_cards')) {
        this.headers = [
          ...this.headers,
          {text: 'CTM Percentage', value: 'ctm_percentage', align: 'center'},
          {text: 'CTM Bank Carges', value: 'ctm_bank_charges', align: 'center'},
          {text: 'Edit', value: 'edit', align: 'center'},
        ];
      }
    },
  };
</script>