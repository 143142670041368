<template>
  <v-container fluid class="text-left">
    <div v-for="(service, index) in servicesOpted" :key="index" class="py-4">
      <div class="d-flex justify-space-between">
        <div>
          <div class="subtitle-1 pr-4">
            {{index + 1}}. {{ service.service_name }}
          </div>
          <div class="text-caption font-italic">
            Supplier: {{service.supplier}}
          </div>
          <div v-if="service.metadata.arrange_by_owner" class="text-caption font-italic">
            Arranged By Principal
          </div>
        </div>
        <div class="font-weight-bold">
          <nobr>$ {{service.amount}}</nobr>
        </div>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="float-right font-weight-bold subtitle-1">Total Amount: $ {{totalAmount}}</div>
  </v-container>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 's-show-services-opted',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      vesselInfo: {
        type: Object,
        default: {},
      },
    },
    data () {
      return {
        servicesOpted: [],
      };
    },
    computed: {
      totalAmount() {
        return _.sum(this.servicesOpted.map(item => parseFloat(item.amount)));
      }
    },
    methods: {
      handleOperationInit() {
        this.getBilledItems();
      },
      getBilledItems() {
        this.$api.operation_billed_items.billed_items_checklist(this.operation.id)
          .then((response) => {
            this.servicesOpted = response.checklist;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>

<style scoped>
  .job-row__border {
    border-bottom: 1px solid lightgray;
  }
</style>