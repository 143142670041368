import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Login from '../components/public/Login';
import Home from '../components/dashboard';
import Profile from '../components/users/Profile';
import User from '../components/users';
import Card from '../components/cards';
import Vessel from '../components/vessels';
import ServiceCatalog from '../components/service_catalogs';
import Operation from '../components/operations';
import showOperationReport from '../components/operations/showOperationReport';
import showOperationQuote from '../components/operations/showOperationQuote';
import ServiceCatalogPricing from '../components/service_catalog_pricings';
import VesselVoyages from '../components/vessel_voyages';
import Dashboard from '../components/users_dashboard';
import SupplyBoat from '../components/service_catalog_pricings/supplyBoat';
import PassengerBoat from '../components/service_catalog_pricings/passengerBoat';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {public: true}
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/users',
      name: 'users',
      component: User,
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Card,
    },
    {
      path: '/vessels',
      name: 'vessels',
      component: Vessel,
    },
    {
      path: '/service_catalogs',
      name: 'service catalogs',
      component: ServiceCatalog,
    },
    {
      path: '/port_calls',
      name: 'port calls',
      component: Operation
    },
    {
      path: '/operations/:id',
      name: 'showOperationReport',
      component: showOperationReport,
    },
    {
      path: '/operations/:type/:id',
      name: 'showOperationQuote',
      component: showOperationQuote,
    },
    {
      path: '/service_catalog_pricings',
      name: 'service catalog pricings',
      component: ServiceCatalogPricing,
    },
    {
      path: '/voyage_tracker',
      name: 'voyage tracker',
      component: VesselVoyages,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/service_catalog_pricings/supply_boat',
      name: 'SupplyBoat',
      component: SupplyBoat,
    },
    {
      path: '/service_catalog_pricings/passenger_boat',
      name: 'PassengerBoat',
      component: PassengerBoat,
    },
  ],
});

// sync routes with auth module
store.dispatch('auth/syncRouter', router);

export default router;
