<template>
  <v-container fluid>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab bottom right fixed color="primary"
          v-bind="attrs" v-on="on" @click="dialogForm = {action: 'Create Passenger Boat Pricing', showForm: true, passenger_boat_pricing: {}}">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Create Passenger Boat Pricing</span>
    </v-tooltip>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :no-data-text="'No data found'"
          :no-results-text="'No results found'"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon @click="dialogForm = {action: 'Edit Passenger Boat Pricing', showForm: true, passenger_boat_pricing: Object.assign(item)}">
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
        </template>
        <template v-slot:progress>
          <div class="full-width text-center ma-4">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <PassengerBoatPricingForm 
      :passenger_boat_pricing="dialogForm.passenger_boat_pricing"
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      :suppliers="suppliers"
      @formSubmitted="resetDialog();getPassengerBoatPricings();"
      @closeDialog="resetDialog()" />
  </v-container>
</template>

<script>
  import PassengerBoatPricingForm from './blocks/PassengerBoatPricingForm';

  export default {
    name: 's-passenger-boat-pricing',
    components: {
      PassengerBoatPricingForm,
    },
    data() {
      return {
        dialogForm: {
          showForm: false,
          action: '',
          passenger_boat_pricing: {},
        },
        search: '',
        loading: false,
        headers: [
          {text: 'Edit', value: 'edit', align: 'start'},
          {text: 'Supplier', value: 'card_name', align: 'center'},
          {text: 'Launch Location', value: 'launch_location', align: 'center'},
          {text: 'Anchorage Location', value: 'anchorage_location', align: 'center'},
          {text: 'Sub Sector', value: 'sub_sector', align: 'center'},
          {text: 'Day / Night', value: 'day', align: 'center'},
          {text: 'Price', value: 'price', align: 'center'},
          {text: 'Fuel Surcharge', value: 'fuel_surcharge', align: 'center'},
        ],
        items: [],
        services: [],
        suppliers: []
      };
    },
    methods: {
      resetDialog() {
        this.dialogForm = {showForm: false, action: '', passenger_boat_pricing: {}};
      },
      getPassengerBoatPricings() {
        this.loading = true;
        this.$api.passenger_boat_pricings.index()
          .then((response) => {
            this.items = response.passenger_boat_pricing;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      populateCards(field, card_type) {
        this.$api.cards.index(card_type)
          .then((response) => {
            this[field] = response.cards;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      }
    },
    created() {
      if (!this.$root.hasAccess('')) {
        this.$store.dispatch('setAlert', {
          status: 'error',
          show: true,
          message: "Please contact admin to access this page",
        });
        this.$router.push({name: 'home'});
      }
      this.populateCards('suppliers', 'supplier');
      this.getPassengerBoatPricings();
    },
  };
</script>