<template>
  <v-select
    label="Freshwater Quantity"
    placeholder="Select Freshwater Quantity"
    outlined
    :rules="[rules.required]"
    dense
    v-model="selectedQuantity"
    prepend-inner-icon="mdi-water-plus"
    :items="freshwaterOptions"
    item-text="name"
    item-value="value"
  ></v-select>
</template>

<script>
  import { FRESHWATER_OPTIONS } from '../../../../services/constants/constants';

  export default {
    props: {
      metadata: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        localMetadata: {
          quantity: ''
        },
        freshwaterOptions: FRESHWATER_OPTIONS,
        selectedQuantity: null,
        rules: {
          required: (value) => !!value || 'Required.',
        },
      };
    },
    watch: {
      metadata: {
        handler(newMetadata) {
          this.localMetadata = { ...this.localMetadata, ...newMetadata };
          this.setInitialQuantity();
        },
        immediate: true,
        deep: true,
      },
      selectedQuantity(newValue) {
        this.localMetadata.quantity = newValue;
      },
    },
    methods: {
      getMetadata() {
        return this.localMetadata;
      },
      setInitialQuantity() {
        const quantityOption = this.freshwaterOptions.find(option => {
          return option.name === this.localMetadata.quantity;
        });
        this.$nextTick(() => {
          this.selectedQuantity = quantityOption ? quantityOption.value : null;
        });
      },
    },
    created() {
      this.setInitialQuantity();
    },
  };
</script>