import { getProfile } from '../../../services/api/users';

export function setProfile({ commit }, profile) {
  commit('updateProfile', profile);
  return Promise.resolve();
};

export function setIsAuthChecked({ commit }, flag) {
  commit('updateIsAuthChecked', flag);
  return Promise.resolve();
};

export function syncRouter({ state, dispatch, rootState }, sinodaRouter) {
  sinodaRouter.beforeEach((to, from, next) => {
    if (state.isAuthChecked && !state.profile.email && to.name == 'login') {
      next();
    } else {
      dispatch('syncProfile', next)
      .then((response) => {
        evaluateRoute(state, rootState, dispatch, to, from, next);
      }).catch(() => {
        dispatch('setProfile', {});
        next({ name: 'login' });
      });
    }
  });
};

function evaluateRoute(state, rootState, dispatch, to, from, next) {
  if (to.matched.some(record => !record.meta.public)) {
    if (!state.profile.email) {
      dispatch('setAlert', 
        {status: 'error', show: true, message: 'Please login to continue'}, 
        {root: true}
      );
      next({ name: 'login' });
    } else {
      if (to.name === 'login') {
        dispatch('setAlert', { status: 'success', show: true, message: 'Already Logged in' }, { root: true });
        next('/');
      } else next();
    }
  } else if (to.name === 'login') {
    if (state.profile.email) {
      dispatch('setAlert', { status: 'success', show: true, message: 'Already Logged in' }, { root: true });
      next({name: 'home'}); // make sure to always call next()!
    } else next();
  } else next();
}

export function syncProfile({ state, dispatch, commit }, next) {
  if (state.profile.email) {
    return Promise.resolve(state.profile);
  } else {
    return getProfile()
      .then((response) => {
        commit('updateProfile', response.profile);
        return Promise.resolve(response.profile);
      }).catch((err) => {
        return dispatch('setIsAuthChecked', true)
          .then(() => {
            return Promise.reject();
          });
      });
  }
}