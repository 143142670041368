<template>
  <v-container fluid>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
      open-on-hover
      :direction="'top'"
      :transition="'slide-y-reverse-transition'"
    >
      <template v-slot:activator v-if="operation.id">
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-file-multiple
          </v-icon>
        </v-btn>
      </template>
      <!-- <v-tooltip left v-if="operation.clone_id || operation.is_draft">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="green"
            v-on="on"
            @click="$router.push({name: 'showOperationReport', params: {id: operation.clone_id}})"
          >
            <v-icon>mdi-ferry</v-icon>
          </v-btn>
        </template>
        <span>PDA Vessel Report</span>
      </v-tooltip> -->
      <v-tooltip left v-if="operation.clone_id || operation.is_draft">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="indigo"
            v-on="on"
            @click="generateReport(operation.clone_id || operation.id)"
          >
            <v-icon>mdi-clipboard-text</v-icon>
          </v-btn>
        </template>
        <span>PDA</span>
      </v-tooltip>
      <v-tooltip left v-if="!operation.is_draft && operation.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="green"
            v-on="on"
            @click="$router.push({name: 'showOperationReport', params: {id: operation.id}})"
          >
            <v-icon>mdi-ferry</v-icon>
          </v-btn>
        </template>
        <span>Vessel Report</span>
      </v-tooltip>
      <v-tooltip left v-if="!operation.is_draft && operation.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="indigo"
            v-on="on"
            @click="generateReport(operation.id)"
          >
            <v-icon>mdi-clipboard-text</v-icon>
          </v-btn>
        </template>
        <span>FDA</span>
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
  export default {
    name: 's-global-quote-details',
    props: {
      operation: {
        type: Object,
        default: {},
      },
    },
    data(){
      return {
        fab: false,
      };
    },
    methods: {
      generateReport(operationId) {
        this.$api.operations.generate_report(operationId)
          .then(response => {
            const url = window.URL.createObjectURL(response.data)

            const link = document.createElement('a')
            link.href = url
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
            link.remove()
          }).catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => {});
      }
    }
  };
</script>