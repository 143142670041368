<template>
  <div>
    <v-select
      label="Type"
      placeholder="Select a Type"
      outlined
      :rules="[rules.required]"
      dense
      v-model="localMetadata.type_of_waste"
      prepend-inner-icon="mdi-food-takeout-box"
      :items="wasteTypes"
    ></v-select>
    <v-select
     	v-if="shouldShowCBM"
      label="CBM"
      placeholder="Select CBM range"
      outlined
      dense
      v-model="selectedCBM"
      prepend-inner-icon="mdi-shipping-pallet"
      :items="cbmOptions"
      item-text="name"
      item-value="value"
    ></v-select>
  </div>
</template>

<script>
  import { CBM_OPTIONS } from '../../../../services/constants/constants';

  export default {
    props: {
      metadata: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        localMetadata: {
          type_of_waste: '',
          cbm: ''
        },
        wasteTypes: ['Food / Plastic / Wood', 'Sludge / Chemical', 'Oily Rags / Ash', 'Pyrotechnic', 'Expired Medicines'],
        selectedCBM: null,
        cbmOptions: CBM_OPTIONS,
        rules: {
          required: (value) => !!value || 'Required.',
        },
      };
    },
    computed: {
      shouldShowCBM() {
        return ['Food / Plastic / Wood'].includes(this.localMetadata.type_of_waste);
      }
    },
     watch: {
      metadata: {
        handler(newMetadata) {
          this.localMetadata = { ...this.localMetadata, ...newMetadata };
          this.setCBM();
        },
        immediate: true,
        deep: true,
      },
      selectedCBM(newValue) {
        this.localMetadata.cbm = newValue;
      },
    },
    methods: {
      getMetadata() {
        return this.localMetadata;
      },
      setCBM() {
        const cbmOption = this.cbmOptions.find(option => {
          return option.name === this.localMetadata.cbm;
        });
        this.$nextTick(() => {
          this.selectedCBM = cbmOption ? cbmOption.value : null;
        });
      },
    },
    created() {
      this.setCBM();
    },
  };
</script>