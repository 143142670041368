import http from '../axios';

export function index(card_type = '') {
  return http.get('/cards?card_type=' + card_type);
}

export function getCard(cardId) {
  return http.get('/cards/' + cardId);
}

export function update(payload) {
  return http.patch(`/cards/${payload.id}`, payload);
}

export function create(payload){
  return http.post('/cards', payload);
}