<template>
  <v-container fluid>
    <div v-if="servicesOptedSorted.length > 0">
      <div v-for="(service, index) in servicesOptedSorted" :key="service.id">
        <v-form :ref="'form' + index" v-model="form[index]" @submit.prevent="submit">
          <div class="headline">{{service.service_name}}</div>
          <div class="subtitle-2 text--darken-2 pt-2 pb-4">Supplier : <b>{{service.supplier}}</b></div>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-text-field
                label="Invoice Amount"
                placeholder="Enter Invoice Amount"
                outlined
                dense
                type="number"
                :rules="[rules.required]"
                v-model="service.amount"
                prepend-inner-icon="mdi-cash"
                clearable
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-menu
                v-model="invoiceDateMenu[index]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="service.invoice_date"
                    label="Invoice Date"
                    dense
                    :rules="[rules.required]"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="service.invoice_date"
                  no-title
                  @input="invoiceDateMenu[index] = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-text-field
                label="Receipt Number"
                placeholder="Enter Receipt Number"
                outlined
                dense
                v-model="service.receipt_number"
                prepend-inner-icon="mdi-note"
                clearable
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" class="pb-0">
              <v-textarea
                label="Remarks"
                placeholder="Enter Remarks"
                outlined
                dense
                hide-details
                :rows="2"
                v-model="service.remarks"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="pb-0" v-if="service.file_urls">
              <v-chip-group
                active-class="primary--text"
                column
              >
                <v-chip
                  v-for="(file, index) in service.file_urls"
                  outlined color="primary" @click="openFile(file.url)"
                  :key="index"
                  close
                  :loading="removeLoading"
                  :disabled="removeLoading"
                  @click:close="removeFile(service, file.file_id)"
                >
                  <v-icon class="mr-2">mdi-cloud-download</v-icon>
                  {{file.filename}}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col v-if="service.supplier !== 'Sinoda'" cols="12" sm="6" md="4" lg="4" class="pb-0">
              <!-- v-text-field
                label="Invoice PDF"
                placeholder="Select PDF"
                outlined
                dense
                v-model="service.invoice_pdf_link.url"
                prepend-inner-icon="mdi-file"
                append-icon="mdi-attachment mdi-rotate-135"
                clearable
                @change="handlePdfUpload"
                type="file"
              >
              </v-text-field> -->
              <v-file-input
                outlined
                dense
                chips
                multiple
                accept="application/pdf"
                :loading="uploadLoading"
                :disabled="uploadLoading"
                v-model="filesUpload"
                label="Select Invoice Files For Upload"
                @change="uploadAsset($event, service)"
              ></v-file-input>
            </v-col>
          </v-row>
          <!-- <v-btn color="success" :disabled="loading[index]" :loading="loading[index]"
            @click="getConsolidatedPdf(index)" outlined>Download Consolidated PDF</v-btn> -->
          <v-btn color="success" :disabled="loading[index]" :loading="loading[index]"
            @click="formSubmit(index)" outlined>Save</v-btn>
          <!-- <v-btn color="primary" outlined>Send Email</v-btn> -->
          <v-divider class="my-5"></v-divider>
        </v-form>
      </div>
      <ReportDetails :operation="operation" />
      <v-btn color="success" @click="$emit('completeJob', operation)">
        Mark As Billed
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import rules from '../../../utilities/validator';
  import ReportDetails from '../ReportDetails';

  export default {
    name: 's-receipts',
    props: {
      operation: {
        type: Object,
        default: {},
      }
    },
    components: {
      ReportDetails
    },
    data() {
      return {
        invoiceDateMenu: [],
        servicesOpted: [],
        loading: [],
        form: [],
        rules,
        filesUpload: [],
        uploadLoading: false,
        removeLoading: false,
        servicesOptedSorted: [],
      };
    },
    methods: {
      openFile(url) {
        window.open(url, '_blank');
      },
      uploadAsset(files, service) {
        if (this.uploadLoading) {
          return;
        }
        if (!files) {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: 'Please select an asset to upload.',
          });
          return;
        }
        this.uploadLoading = true;
        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
          formData.append("files[]", files[i]);
        }
        formData.append("id", service.id);
        this.$api.operation_billed_items.upload_receipts(formData)
          .then(response => {
            this.filesUpload = [];
            setTimeout(function(){
              service.file_urls = response.urls;
            }, 100);
            this.$store.dispatch('setAlert', {
              message: 'Files uploaded successfully!',
            });
          })
          .catch(error => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: 'Failed to upload file.',
            });
          })
          .finally(() => {
            this.uploadLoading = false;
          });
      },
      removeFile(service, id) {
        this.removeLoading = true;
        const self = this;
        this.$api.operation_billed_items.remove_receipt({ operation_id: service.id, id: id})
          .then(response => {
            setTimeout(function() {
              if (response.success) {
                service.file_urls = service.file_urls.filter(x => x.file_id != id);
                self.$store.dispatch('setAlert', {
                  message: 'File removed successfully!',
                });
              } else {
                self.$store.dispatch('setAlert', {
                  status: 'error',
                  show: true,
                  message: 'Failed to remove file.',
                });
              }
            }, 100);
          })
          .catch(error => {
            self.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: 'Failed to remove file.',
            });
          })
          .finally(() => {
            this.removeLoading = false;
          });
      },
      handleOperationInit() {
        this.getBilledItems();
      },
      // getConsolidatedPdf(index) {
      //   this.loading[index] = true;
      //   this.$api.operation_billed_items.consolidated_report(this.servicesOpted[index].id)
      //     .then(response => {
      //       console.log('cd',response.data)
      //       var b = new Blob([response.data], { type: "application/pdf" });
      //       const url = window.URL.createObjectURL(b)

      //       const link = document.createElement('a')
      //       link.href = url
      //       link.setAttribute('target', '_blank')
      //       document.body.appendChild(link)
      //       link.click()
      //       link.remove()
      //     }).catch(err => {
      //       this.$store.dispatch('setAlert', {
      //         status: 'error',
      //         show: true,
      //         message: err.message,
      //       });
      //     }).finally(() => this.loading[index] = false);
      // },
      formSubmit(index) {
        let formRef = 'form' + index;
        formRef = this.$refs[formRef];
        if (!formRef[0].validate()) return;
        this.loading[index] = true;
        this.$api.operation_billed_items.update(this.servicesOpted[index])
          .then((response) => {
            this.loading[index] = false;
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Billed Item updated successfully',
            });
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getBilledItems() {
        this.$api.operation_billed_items.billed_items_checklist(this.operation.id)
          .then((response) => {
            this.servicesOpted = response.checklist;
            this.servicesOptedSorted = this.servicesOpted.map(service => ({
              ...service,
              amount: parseFloat(service.amount).toFixed(2)
            })).sort((a, b) => {
              return a.service_name.localeCompare(b.service_name);
            });
            this.servicesOptedSorted.forEach(item => {
              this.invoiceDateMenu.push(false);
              this.form.push(false);
              this.loading.push(false);
            })
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
        },
      formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-');
        return `${month}/${day}/${year}`;
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>