<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="form" @submit.prevent="submit">
          <v-card-text class="pb-0">
            <v-text-field
              label="Name"
              placeholder="Enter Name"
              :rules="[rules.name]"
              outlined
              dense
              v-model="userForm.name"
              prepend-inner-icon="mdi-account"
              clearable
              :disabled="action == 'Update Password'"
            ></v-text-field>
            <div v-if="action != 'Update Password'">
              <v-text-field
                label="Email"
                placeholder="Enter Email Address"
                :rules="[rules.email]"
                outlined
                dense
                v-model="userForm.email"
                prepend-inner-icon="mdi-email"
                clearable
              ></v-text-field>
              <v-text-field
                label="Mobile"
                placeholder="Enter Mobile Number"
                :rules="[rules.mobile]"
                outlined
                dense
                v-model="userForm.mobile"
                prepend-inner-icon="mdi-phone"
                clearable
              ></v-text-field>
            </div>
            <div v-if="isAdmin && action != 'Update Password'">
              <v-select
                label="Role"
                placeholder="Select a role"
                :rules="[rules.required]"
                outlined
                dense
                v-model="userForm.role"
                prepend-inner-icon="mdi-crown"
                :items="roles"
              ></v-select>
              <v-select
                label="Privileges"
                placeholder="Select privileges"
                outlined
                dense
                multiple
                v-model="userForm.privileges"
                prepend-inner-icon="mdi-crown"
                :items="allPrivileges"
              ></v-select>
              <v-switch dense class="mt-0" v-model="userForm.is_active" label="Active / Inactive">
              </v-switch>
            </div>
            <div v-if="['Create User', 'Update Password'].includes(action)">
              <v-text-field
                label="Password"
                placeholder="Enter Password"
                :rules="[rules.required, rules.password]"
                outlined
                dense
                v-model="userForm.password"
                prepend-inner-icon="mdi-key"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                clearable
              ></v-text-field>
              <v-text-field
                label="Confirm Password"
                placeholder="Confirm Password"
                :rules="[rules.required, rules.confirm(userForm.password)]"
                outlined
                dense
                v-model="userForm.password_confirmation"
                prepend-inner-icon="mdi-key"
                :append-icon="confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="confirmPassword = !confirmPassword"
                :type="confirmPassword ? 'text' : 'password'"
                clearable
              ></v-text-field>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="primary" outlined @click="formSubmit()" 
              :disabled="loading" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '../../../utilities/validator';

  export default {
    name: 's-user-form',
    props: {
      action: {
        type: String,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
      user: {
        type: Object,
        default: {},
      },
    },
    data() {
      let userObj = {
        name: '',
        email: '',
        mobile: '',
        role: '',
        is_active: true,
        password: '',
        password_confirmation: '',
        privileges: [],
      };

      return {
        roles: ['operations', 'accounts', 'manager', 'admin'],
        form: false,
        confirmPassword: false,
        showPassword: false,
        rules,
        loading: false,
        tmpForm: Object.assign({}, userObj),
        userForm: Object.assign({}, userObj),
        allPrivileges: ['can_manage_operation_amount_details', 'can_create_vessel', 'can_manage_cards', 'can_manage_fda'],
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      formSubmit() {
        if(!this.submit()) return;
        this.loading = true;
        if (!this.user.id) this.createUser();
        else if (this.action == 'Update Password') this.updatePassword();
        else this.updateUser();
      },
      createUser() {
        this.$api.users.create(this.userForm)
          .then((response) => {
            this.loading = false;
            this.userForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'User created successfully',
            });
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      updatePassword() {
        this.$api.users.updatePassword({
          id: this.userForm.id,
          password_confirmation: this.userForm.password_confirmation,
        }).then((response) => {
            this.loading = false;
            this.userForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Password updated successfully',
            });
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      updateUser() {
        let formData = {
          id: this.userForm.id,
          name: this.userForm.name,
          email: this.userForm.email,
          mobile: this.userForm.mobile,
        };
        if (this.isAdmin) {
          formData.is_active = this.userForm.is_active;
          formData.role = this.userForm.role;
          formData.privileges = this.userForm.privileges;
        }
        this.$api.users.update(formData)
          .then((response) => {
            this.loading = false;
            this.userForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'User updated successfully',
            });
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    watch: {
      user(value) {
        this.userForm = Object.assign({}, value);
        if (!value.id) this.$refs.form && this.$refs.form.reset();
      },
    },
    created() {
      this.userForm.role = this.roles[0];
    },
  };
</script>