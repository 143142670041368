<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="form" @submit.prevent="submit">
          <v-card-text class="pb-0">
            <v-text-field
              label="Name"
              placeholder="Enter Name"
              :rules="[rules.required]"
              outlined
              dense
              v-model="cardForm.name"
              prepend-inner-icon="mdi-account"
              clearable
            ></v-text-field>
            <v-text-field
              label="Email"
              placeholder="Enter Email Address"
              :rules="[rules.email, rules.required]"
              outlined
              dense
              :disabled="cardForm.card_type === 'subsidiary'"
              v-model="cardForm.email"
              prepend-inner-icon="mdi-email"
              clearable
            ></v-text-field>
            <v-text-field
              label="Mobile"
              placeholder="Enter Mobile Number"
              outlined
              dense
              v-model="cardForm.mobile"
              prepend-inner-icon="mdi-phone"
              clearable
            ></v-text-field>
            <v-select
              label="Contact Type"
              placeholder="Select a Contact type"
              :rules="[rules.required]"
              outlined
              dense
              v-model="cardForm.card_type"
              prepend-inner-icon="mdi-crown"
              :items="card_types"
              @change="loadParentCompany"
            ></v-select>
            <v-select v-if="cardForm.card_type == 'subsidiary'"
              label="Parent Company"
              placeholder="Select a Parent Company"
              :rules="[rules.required]"
              outlined
              dense
              v-model="cardForm.card_id"
              prepend-inner-icon="mdi-crown"
              :items="parent"
              item-text="name"
              item-value="id"
              return-object
              clearable
              @change="changeParent($event)"
            ></v-select>
            <v-switch dense class="mt-0" v-model="cardForm.is_active" label="Active / Inactive">
            </v-switch>
            <v-text-field
              label="Address"
              placeholder="Enter Address"
              outlined
              dense
              v-model="cardForm.address"
              prepend-inner-icon="mdi-city"
              clearable
            ></v-text-field>
            <v-text-field
              label="City"
              placeholder="Enter City"
              outlined
              dense
              v-model="cardForm.city"
              prepend-inner-icon="mdi-city"
              clearable
            ></v-text-field>
            <v-text-field
              label="State"
              placeholder="Enter State"
              outlined
              dense
              v-model="cardForm.state"
              prepend-inner-icon="mdi-city"
              clearable
            ></v-text-field>
            <v-text-field
              label="Country"
              placeholder="Enter Country"
              outlined
              dense
              v-model="cardForm.country"
              prepend-inner-icon="mdi-city"
              clearable
            ></v-text-field>
            <v-text-field
              label="Postcode"
              placeholder="Enter postcode"
              outlined
              dense
              v-model="cardForm.postcode"
              prepend-inner-icon="mdi-city"
              clearable
            ></v-text-field>
            <v-text-field
              v-if="cardForm.card_type == 'customer'"
              label="CTM Percentage"
              placeholder="Enter CTM Percentage"
              outlined
              dense
              type="number"
              v-model="cardForm.ctm_percentage"
              prepend-inner-icon="mdi-percent"
              clearable
            ></v-text-field>
            <v-text-field
              v-if="cardForm.card_type == 'customer'"
              label="CTM Bank Charges"
              placeholder="Enter CTM Bank Charges"
              outlined
              dense
              type="number"
              v-model="cardForm.ctm_bank_charges"
              prepend-inner-icon="mdi-cash"
              clearable
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="primary" outlined @click="formSubmit()" 
              :disabled="loading" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '../../../utilities/validator';
  import _ from 'lodash';

  export default {
    name: 's-card-form',
    props: {
      action: {
        type: String,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      card: {
        type: Object,
        default: {},
      },
    },
    data() {
      let cardObj = {
        name: '',
        email: '',
        mobile: '',
        card_type: '',
        is_active: true,
        address: '',
        city: '',
        state: '',
        country: '',
        postcode: '',
        card_id: '',
        ctm_percentage: 1.5,
        ctm_bank_charges: 40
      };

      return {
        card_types: ['customer', 'supplier', 'subsidiary'],
        parent: [],
        form: false,
        rules,
        loading: false,
        tmpForm: Object.assign({}, cardObj),
        cardForm: Object.assign({}, cardObj),
      };
    },
    methods: {
      changeParent(object) {
        if (object && object.email) {
          this.cardForm.email = object.email;
        }
      },
      loadParentCompany(card_type) {
        if (card_type === 'subsidiary') {
          this.$api.cards.index('customer')
            .then((response) => {
              if (this.cardForm.card_id) {
                const card = _.find(response.cards, {id: this.cardForm.card_id});
                if (card && card.id) {
                  this.changeParent(card);
                }
              }
              this.parent = response.cards;
            }).catch((err) => {
              this.$store.dispatch('setAlert', {
                status: 'error',
                show: true,
                message: err.message,
              });
            });
        } else {
          this.cardForm.card_id = null;
        }
      },
      submit() {
        return this.$refs.form.validate();
      },
      formSubmit() {
        if(!this.submit()) return;
        this.loading = true;
        if (!this.card.id) this.createCard();
        else this.updateCard();
      },
      createCard() {
        if (this.cardForm.card_type === 'subsidiary' && this.cardForm.card_id) {
          this.cardForm.email = '';
          this.cardForm.card_id = this.cardForm.card_id.id;
        }
        this.$api.cards.create(this.cardForm)
          .then((response) => {
            this.loading = false;
            this.cardForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Contact created successfully',
            });
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      updateCard() {
        if (this.cardForm.card_type === 'subsidiary' && this.cardForm.card_id) {
          this.cardForm.email = '';
          this.cardForm.card_id = this.cardForm.card_id.id;
        }
        this.$api.cards.update(this.cardForm)
          .then((response) => {
            this.loading = false;
            this.cardForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Contact updated successfully',
            });
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    watch: {
      card(value) {
        this.cardForm = Object.assign({}, value);
        if (this.cardForm.card_type === 'subsidiary') {
          this.loadParentCompany('subsidiary');
        }
        if (!value.id) this.$refs.form && this.$refs.form.reset();
      },
    },
    created() {
      this.cardForm.role = this.card_types[0];
    },
  };
</script>