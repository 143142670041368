<template>
  <v-container fluid>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab bottom right fixed color="primary"
          v-bind="attrs" v-on="on" @click="dialogForm = {action: 'Create Service', showForm: true, service_catalog: {}}">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Create Service</span>
    </v-tooltip>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :no-data-text="'No data found'"
          :no-results-text="'No results found'"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon @click="dialogForm = {action: 'Edit Service', showForm: true, service_catalog: Object.assign(item)}">
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.is_active="{ item }">
          <span>{{ item.is_active ? 'Active' : 'Inactive'}}</span>
        </template>
        <template v-slot:item.suppliers="{ item }">
          <span>{{ item.suppliers.map(x => x.name).join(', ')}}</span>
        </template>
        <template v-slot:progress>
          <div class="full-width text-center ma-4">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <service-catalog-form 
      :service_catalog="dialogForm.service_catalog"
      :suppliers="activeSuppliers"
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      @formSubmitted="resetDialog();getServiceCatalogs();"
      @closeDialog="resetDialog()" />
  </v-container>
</template>

<script>
  import ServiceCatalogForm from './blocks/ServiceCatalogForm';

  export default {
    name: 's-service-catalogs',
    components: {
      ServiceCatalogForm,
    },
    data() {
      return {
        dialogForm: {
          showForm: false,
          action: '',
          service_catalog: {},
        },
        search: '',
        loading: false,
        headers: [
          {text: 'Name', value: 'name', align: 'center'},
          {text: 'Category', value: 'category', align: 'center'},
          {text: 'Suppliers', value: 'suppliers', align: 'center'},
          {text: 'Is Active', value: 'is_active', align: 'center'},
          {text: 'Edit', value: 'edit', align: 'center'},
        ],
        items: [],
        activeSuppliers: [],
      };
    },
    methods: {
      resetDialog() {
        this.dialogForm = {showForm: false, action: '', service_catalog: {}};
      },
      getServiceCatalogs() {
        this.loading = true;
        this.$api.service_catalogs.index()
          .then((response) => {
            this.items = response.service_catalogs;
            this.activeSuppliers = response.suppliers;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    created() {
      if (!this.$root.hasAccess('')) {
        this.$store.dispatch('setAlert', {
          status: 'error',
          show: true,
          message: "Please contact admin to access this page",
        });
        this.$router.push({name: 'home'});
      }
      this.getServiceCatalogs();
    },
  };
</script>