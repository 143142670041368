<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ action }}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="form" @submit.prevent="submit">
          <v-card-text class="pb-0">
            <v-select
              label="Service"
              placeholder="Select a service"
              outlined
              :rules="[rules.required]"
              dense
              v-model="serviceCatalogPricingForm.service_catalog_id"
              prepend-inner-icon="mdi-cards-variant"
              :items="services"
              item-text="name"
              item-value="id"
              @change="onServiceChange"
            ></v-select>
            <component
              :is="serviceComponent"
              ref="serviceComponentRef"
              :metadata="serviceCatalogPricingForm.metadata"
            ></component>
            <v-text-field
              label="Price"
              placeholder="Enter Price"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              v-model="serviceCatalogPricingForm.price"
              prepend-inner-icon="mdi-currency-usd"
              clearable
            ></v-text-field>
            <v-select
              label="Supplier"
              placeholder="Select a supplier"
              outlined
              dense
              v-model="serviceCatalogPricingForm.card_id"
              prepend-inner-icon="mdi-account"
              :items="suppliers"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="primary" outlined @click="formSubmit()" :disabled="loading" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import TransportServicePricingForm from './pricings/TransportServicePricingForm.vue';
  import FreshwaterPricingForm from './pricings/FreshwaterPricingForm.vue';
  import GarbageRemovalPricingForm from './pricings/GarbageRemovalPricingForm.vue';
  import rules from '../../../utilities/validator';

  export default {
    name: 's-service-catalog-form',
    components: {
      TransportServicePricingForm,
      FreshwaterPricingForm,
      GarbageRemovalPricingForm
    },
    props: {
      action: {
        type: String,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      suppliers: Array,
      services: Array,
      service_catalog_pricing: {
        type: Object,
        default: {},
      },
    },
    data() {
      let serviceCatalogPricingObj = {
        card_id: '',
        price: '',
        service_catalog_id: '',
        metadata: {}
      };

      return {
        form: false,
        rules,
        loading: false,
        serviceComponent: null,
        serviceCatalogPricingForm: Object.assign({}, serviceCatalogPricingObj),
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      onServiceChange() {
        const selectedService = this.services.find(
          (service) => service.id === this.serviceCatalogPricingForm.service_catalog_id
        );
        if (selectedService) {
          if (selectedService.name === 'Transport Service') {
            this.serviceComponent = 'TransportServicePricingForm';
          } else if (selectedService.name === 'Freshwater') {
            this.serviceComponent = 'FreshwaterPricingForm';
          } else if (selectedService.name === 'Garbage Disposal') {
            this.serviceComponent = 'GarbageRemovalPricingForm';
          } else {
            this.serviceComponent = null;
          }
        }
      },
      formSubmit() {
        if (!this.submit()) return;
        this.loading = true;
        const childMetadata = this.$refs.serviceComponentRef.getMetadata();
        this.serviceCatalogPricingForm.metadata = childMetadata;
        this.saveService();
      },
      saveService() {
        this.$api.service_catalog_pricings.save_service(this.serviceCatalogPricingForm)
          .then((response) => {
            this.serviceCatalogPricingForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Service Catalog Pricing saved successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
    },
    watch: {
      showForm(newVal) {
        if (newVal && !this.serviceCatalogPricingForm.id) {
          this.serviceComponent = null;
        }
      },
      service_catalog_pricing(value) {
        this.serviceCatalogPricingForm = Object.assign({}, value);
        if (!this.serviceCatalogPricingForm.metadata) {
          this.serviceCatalogPricingForm.metadata = {};
        }
        this.onServiceChange();
        if (!value.id) this.$refs.form && this.$refs.form.reset();
      },
    },
  };
</script>