import http from '../axios';
import axios from 'axios';

export function index(payload = {}) {
  return http.get('/operations', {
    params: {
      operation_id: payload.operation_id
    }
  });
}

export function update(payload) {
  return http.patch(`/operations/${payload.id}`, payload);
}

export function create(formData){
  return http.post('/operations', {
    params: formData
  });
}

export function show(payload) {
  return http.get(`/operations/${payload.id}`);
}

export function job_clone(id){
  return http.post('/operations/job_clone', {id});
}

export function generate_report(id){
  return http.get('/operations/generate_report.pdf', {
    params: {
      id
    },
    responseType: 'blob'
  });
}

export function completeJob(id){
  return http.post('/operations/complete_job', {id});
}

export function getPDAApproved(operation_id) {
  return http.get('/operations/get_pda_approved', { params: { operation_id}});
}